import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddDietplanForm from 'whealth-core-web/forms/AddDietplanForm';
import CommonAlert from '../CommonAlert';
import { formatGenderAge, profileImage, handleTextFormatting } from '../Helper';
import Loader from '../Loader';
import Strings from 'constants/string';
import CoreToggleText from '../CoreToggleText';
import CoreTextArea from '../CoreTextArea';
import { Modal } from 'rsuite';
import { blueEdit } from 'res/images';
import { setPatientDetails } from 'redux/Slices';
import CoreInputBox from '../CoreInputBox';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import CoreDropDown from '../CoreDropDown';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AddDietPlan() {
  const [weightUpdatedByHealthCoach, setWeightUpdatedByHealthCoach] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [errors, setErrors] = useState({ diet_plans: [] });
  const [templateList, setTemplateList] = useState([]);
  const [templateValue, setTemplateValue] = useState();
  const [searchTemplateList, setSearchTemplateList] = useState([]);
  const patientDetails = useSelector((state) => state?.sliceReducer?.patientDetails);
  const [userDetails, setUserDetails] = useState({});
  const [isWeightEdit, setWeightEdit] = useState(false);
  const [isMedConEdit, setMedConEdit] = useState(false);
  const [isFoodPefEdit, setFoodPefEdit] = useState(false);
  const [overAllGuidelines, setOverAllGuidelines] = useState('');
  const [patientSearchStr, setPatientSearchStr] = useState('');

  const [metrics, setMetrics] = useState([
    {
      key: '',
      answer: '',
      profile_question_id: null,
    },
  ]);
  const [dietPlans, setDietPlans] = useState([
    {
      templateValue: '',
      dietTemplate: {},
    },
  ]);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const [startTime, setStartTime] = useState(null);
  // const adminData = useSelector((state) => state?.sliceReducer?.adminData);
  let adminData = useGetLoginUserDataQuery()?.data?.data || {};
  const [isShowAlert, setIsShowAlert] = useState(false);
  const { id, pid } = useParams();
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const dietIntensityData = [
    { id: 'Easy', title: 'Easy' },
    { id: 'Moderate', title: 'Moderate' },
    { id: 'Intense', title: 'Intense' },
  ];

  const foodPreferenceOptions = [
    { id: 'Vegetarian', title: 'Vegetarian' },
    { id: 'Non-Vegetarian', title: 'Non-Vegetarian' },
    { id: 'Vegan', title: 'Vegan' },
    { id: 'Eggetarian', title: 'Eggetarian' },
  ];

  useEffect(() => {
    const extractMetrics = () => {
      const profileInfo = userDetails?.profile_info;
      const requiredKeys = ['weight', 'medical_condition', 'food_allergies', 'diet_intensity', 'food_preference'];
      const extractedMetrics = profileInfo
        ?.filter((item) => requiredKeys?.includes(item.key))
        ?.map((item) => ({
          key: item.key,
          answer: item.answer,
          profile_question_id: item.profile_question_id,
        }));
      setMetrics(extractedMetrics);
    };

    extractMetrics();
  }, [userDetails]);

  useEffect(() => {
    getPatientData();
    setStartTime(Date.now());

    mixpanel.track('Time spent in creating a diet plan', {
      page: 'diet_plan_create',
    });

    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;
    };
  }, []);

  useEffect(() => {
    if (id) {
      if (Object.keys(patientDetails).length > 0) {
        setUserDetails(patientDetails);
      } else {
        getPatientData();
      }
    } else {
      setUserDetails({});
    }
  }, [id, patientDetails]);

  useEffect(() => {
    if (pid) {
      getDietPlanData();
    }
  }, [pid]);

  useEffect(() => {
    getDietTemplatesList();
  }, []);

  useEffect(() => {
    if (!pid && templateValue) {
      getTemplateQuestion();
    }
  }, [templateValue]);

  const searchDietTemplate = (data) => {
    setSearchTemplateList([]);
    if (data?.length) {
      ApiClient.searchDietTemplateList({ search_str: data })
        .then((res) => {
          setSearchTemplateList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDietTemplatesList = () => {
    setIsLoading(true);
    ApiClient.getDietTemplateList()
      .then((res) => {
        setTemplateList(res.data);
        let defaultTemplate = res.data.findIndex((item) => item.default);
        if (defaultTemplate !== -1 && !pid) {
          setDietPlans((prevPlans) => {
            const updatedPlans = [...prevPlans];
            updatedPlans[0].templateValue = res.data[defaultTemplate].id;
            return updatedPlans;
          });
          getTemplateQuestion(0, res.data[defaultTemplate].id);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getTemplateQuestion = (index = 0, templateId = null) => {
    setIsLoading(true);
    const templateValueToUse = templateId || dietPlans[index].templateValue;
    ApiClient.getDietTemplate(templateValueToUse)
      .then((res) => {
        const updateDietData = res.data;
        setOverAllGuidelines(updateDietData.guidelines);
        const updatedDietPlans = [...dietPlans];
        updatedDietPlans[index].dietTemplate = {
          id: updateDietData.id,
          title: updateDietData.title,
          guidelines: updateDietData.guidelines,
          default: updateDietData.default,
          diet_plans: updateDietData.diet_plan_day_parts.map((dayPart) => ({
            day_part_title: dayPart.day_part.title,
            day_part_id: dayPart.day_part.id,
            day_part_rank: dayPart.day_part.rank,
            notification_time: dayPart.notification_time,
            note: dayPart.note,
            recipe_link: dayPart.recipe_link,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
              food: foodItem.food,
              quantity: foodItem.quantity,
              recipe_link: foodItem.recipe_link,
              twins: foodItem.twins,
            })),
          })),
        };
        // setDietPlans(updatedDietPlans);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientProfile(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        newuserData?.locations?.map((item) => tempLocations.push(item.id));
        let reformattedData = { ...newuserData, locations: tempLocations };
        reformattedData.care_manager_id = reformattedData?.care_manager?.id;
        reformattedData.care_plan_id = reformattedData?.care_plan?.id;
        setUserDetails(reformattedData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [searchPatientsData, setSearchPatientsData] = useState([]);
  const searchPatients = (admin_id, debouncedPatientSearchStr) => {
    ApiClient.getSearchPatientsOfHealthCoach(debouncedPatientSearchStr, admin_id)
      .then((res) => {
        setSearchPatientsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [userPublishedDietPlans, setUserPublishedDietPlans] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleSelectPatient = (val, objData) => {
    if (objData) {
      setSelectedPatient(objData);
      setIsLoading(true);
      ApiClient.getPublishedDietPlansForUser(objData.id, pid)
        .then((res) => {
          const fetchedDietPlans = res.data;
          setIsLoading(false);
          if (fetchedDietPlans?.length) {
            setUserPublishedDietPlans(fetchedDietPlans);
          } else {
            console.error('No diet plans available for this patient');
            setUserPublishedDietPlans([]);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching diet plans:', error);
          setUserPublishedDietPlans([]);
        });
    }
  };

  const getDietPlanData = () => {
    setIsLoading(true);
    ApiClient.getDietPlanTemplateList(id, false, pid)
      .then((res) => {
        let updateDietData = res.data;
        if (!updateDietData || !Array.isArray(updateDietData.diet_plans)) {
          console.error('Invalid diet plan data structure:', updateDietData);
          setIsLoading(false);
          setIsShowAlert('Error loading diet plan data');
          setAlertType('alert-danger');
          return;
        }

        const updatedDietPlans = updateDietData.diet_plans.map((plan) => ({
          templateValue: plan.id,
          dietTemplate: {
            id: plan.id,
            title: plan.title,
            guidelines: plan.guidelines,
            default: plan.default,
            start_date: plan.start_date,
            end_date: plan.end_date,
            week_days: plan.week_days || [],
            diet_plans: plan.diet_plan_day_parts.map((dayPart) => ({
              day_part_title: dayPart.day_part.title,
              day_part_id: dayPart.day_part.id,
              day_part_rank: dayPart.day_part.rank,
              notification_time: dayPart.notification_time,
              note: dayPart.note,
              recipe_link: dayPart.recipe_link,
              diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
                food: foodItem.food,
                quantity: foodItem.quantity,
                recipe_link: foodItem.recipe_link,
                twins: foodItem.twins,
              })),
            })),
          },
        }));
        setOverAllGuidelines(res.data.guidelines);
        setDietPlans(updatedDietPlans);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching diet plan data:', err);
        setIsLoading(false);
        setIsShowAlert('Error loading diet plan data');
        setAlertType('alert-danger');
      });
  };

  const submitQuestionAnswers = () => {
    setIsLoading(true);
    const metricsList = metrics.map((metric) => ({
      question: metric.key,
      answer: metric.answer,
      profile_question_id: metric.profile_question_id,
    }));
    ApiClient.createProfileAnswers(id, metricsList)
      .then((res) => {
        setIsLoading(false);
        getPatientData();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrors(err.response.data.errors);
      });
  };
  const editCurrentWeightByHealthCoach = () => {
    setIsLoading(true);
    const metricsList = metrics
      .filter((metric) => metric.key !== 'weight')
      .map((metric) => ({
        question: metric.key,
        answer: metric.answer,
        profile_question_id: metric.profile_question_id,
      }));

    ApiClient.getWeightGoalId(id)
      .then((res) => {
        const result = res.data.filter((item) => item.goal.title === 'Weight');
        const patient_goal_id = result[0].id;
        const prescription_medicine_id = res.data[0].prescription_medicine_id;
        ApiClient.activityLogsCreate(id, {
          patient_goal_id: patient_goal_id,
          prescription_medicine_id: prescription_medicine_id,
          value: weightUpdatedByHealthCoach || userDetails?.current_weight,
        })
          .then((res) => {
            ApiClient.createProfileAnswers(id, metricsList).then((res) => {
              setIsLoading(false);
              getPatientData();
            });
            // dispatch(setPatientDetails({...userDetails, current_weight: weightUpdatedByHealthCoach}));
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            setErrors(err.response.data.errors);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [isNewDietPlanReferenced, setIsNewDietPlanReferenced] = useState(false);
  const createDiet = () => {
    const payload = {
      status: 'draft',
      diet_plans: dietPlans.map((dietPlan) => {
        const normalizedDayParts = dietPlan.dietTemplate.diet_plans.map((dayPart) => ({
          ...dayPart,
          notification_time:
            dayPart.notification_time.length === 3 ? `0${dayPart.notification_time}` : dayPart.notification_time,
        }));

        const sortedDayParts = normalizedDayParts.sort((a, b) => {
          if (a.day_part_rank === b.day_part_rank) {
            return parseInt(a.notification_time, 10) - parseInt(b.notification_time, 10);
          }
          return a.day_part_rank - b.day_part_rank;
        });

        return {
          status: 'draft',
          title: dietPlan.dietTemplate.title,
          guidelines: overAllGuidelines,
          start_date: dietPlan.start_date,
          end_date: dietPlan.end_date,
          week_days: dietPlan.dietTemplate.week_days || [],
          diet_plan_day_parts: sortedDayParts.map((dayPart) => ({
            day_part_id: dayPart.day_part_id,
            day_part_rank: dayPart.day_part_rank,
            notification_time: dayPart.notification_time,
            note: dayPart.note,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
              food: foodItem.food,
              quantity: foodItem.quantity,
              recipe_link: foodItem.recipe_link,
              twins: foodItem.twins,
            })),
          })),
          reference_template_id: isNewDietPlanReferenced ? dietPlan.dietTemplate.id : null,
          referenced_from_user: isNewDietPlanReferenced ? selectedPatient.id : null,
        };
      }),
      guidelines: overAllGuidelines,
      title: dietPlans[0].dietTemplate.title,
    };
    setIsLoading(true);
    setErrors({});
    ApiClient.DietPlanCreate(payload, id)
      .then((res) => {
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
        setAlertType('alert-success');
        setIsShowAlert('Diet plan created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(
          err.response.data?.errors?.non_field_errors || err.response.data?.errors?.diet_plans[0]?.non_field_errors
        );
      });
  };

  const updateDiet = () => {
    setIsLoading(true);
    const payload = {
      status: 'draft',
      diet_plans: dietPlans.map((dietPlan) => {
        const normalizedDayParts = dietPlan.dietTemplate.diet_plans.map((dayPart) => ({
          ...dayPart,
          notification_time:
            dayPart.notification_time.length === 3 ? `0${dayPart.notification_time}` : dayPart.notification_time,
        }));

        const sortedDayParts = normalizedDayParts.sort((a, b) => {
          if (a.day_part_rank === b.day_part_rank) {
            return parseInt(a.notification_time, 10) - parseInt(b.notification_time, 10);
          }
          return a.day_part_rank - b.day_part_rank;
        });

        return {
          status: 'draft',
          title: dietPlan.dietTemplate.title,
          guidelines: overAllGuidelines,
          start_date: dietPlan.start_date,
          end_date: dietPlan.end_date,
          week_days: dietPlan.dietTemplate.week_days || [],
          diet_plan_day_parts: sortedDayParts.map((dayPart) => ({
            day_part_id: dayPart.day_part_id,
            day_part_rank: dayPart.day_part_rank,
            notification_time: dayPart.notification_time,
            note: dayPart.note,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
              food: foodItem.food,
              quantity: foodItem.quantity,
              recipe_link: foodItem.recipe_link,
              twins: foodItem.twins,
            })),
          })),
          reference_template_id: isNewDietPlanReferenced ? dietPlan.dietTemplate.id : null,
          referenced_from_user: isNewDietPlanReferenced ? selectedPatient.id : null,
        };
      }),
      guidelines: overAllGuidelines,
      title: dietPlans[0].dietTemplate.title,
    };
    setIsLoading(true);
    ApiClient.DietPlanUpdate(id, pid, payload)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet Plan updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(
          err.response.data?.errors?.non_field_errors || err.response.data?.errors?.diet_plans[0]?.non_field_errors
        );
      });
  };

  const createPublishDiet = () => {
    setIsLoading(true);
    const payload = {
      status: 'published',
      diet_plans: dietPlans.map((dietPlan) => {
        const normalizedDayParts = dietPlan.dietTemplate.diet_plans.map((dayPart) => ({
          ...dayPart,
          notification_time:
            dayPart.notification_time.length === 3 ? `0${dayPart.notification_time}` : dayPart.notification_time,
        }));

        const sortedDayParts = normalizedDayParts.sort((a, b) => {
          if (a.day_part_rank === b.day_part_rank) {
            return parseInt(a.notification_time, 10) - parseInt(b.notification_time, 10);
          }
          return a.day_part_rank - b.day_part_rank;
        });

        return {
          status: 'published',
          title: dietPlan.dietTemplate.title,
          guidelines: overAllGuidelines,
          start_date: dietPlan.start_date,
          end_date: dietPlan.end_date,
          week_days: dietPlan.dietTemplate.week_days || [],
          diet_plan_day_parts: sortedDayParts.map((dayPart) => ({
            day_part_id: dayPart.day_part_id,
            day_part_rank: dayPart.day_part_rank,
            notification_time: dayPart.notification_time,
            note: dayPart.note,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
              food: foodItem.food,
              quantity: foodItem.quantity,
              recipe_link: foodItem.recipe_link,
              twins: foodItem.twins,
            })),
          })),
          reference_template_id: isNewDietPlanReferenced ? dietPlan.dietTemplate.id : null,
          referenced_from_user: isNewDietPlanReferenced ? selectedPatient.id : null,
        };
      }),
      guidelines: overAllGuidelines,
      title: dietPlans[0].dietTemplate.title,
    };

    ApiClient.DietPlanCreatePublish(payload, id)
      .then((res) => {
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet plan created & published successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(
          err.response.data?.errors?.non_field_errors || err.response.data?.errors?.diet_plans[0]?.non_field_errors
        );
      });
    mixpanel.track('Publishing of diet plan');
    mixpanel.people.set({
      $uuid: adminData.uuid,
      $name: adminData.full_name,
      $email: adminData.email,
      $phone: adminData.phone,
    });
  };

  const updatePublishDiet = () => {
    const payload = {
      status: 'published',
      diet_plans: dietPlans.map((dietPlan) => {
        const normalizedDayParts = dietPlan.dietTemplate.diet_plans.map((dayPart) => ({
          ...dayPart,
          notification_time:
            dayPart.notification_time.length === 3 ? `0${dayPart.notification_time}` : dayPart.notification_time,
        }));

        const sortedDayParts = normalizedDayParts.sort((a, b) => {
          if (a.day_part_rank === b.day_part_rank) {
            return parseInt(a.notification_time, 10) - parseInt(b.notification_time, 10);
          }
          return a.day_part_rank - b.day_part_rank;
        });

        return {
          status: 'published',
          title: dietPlan.dietTemplate.title,
          guidelines: overAllGuidelines,
          start_date: dietPlan.start_date,
          end_date: dietPlan.end_date,
          week_days: dietPlan.dietTemplate.week_days || [],
          diet_plan_day_parts: sortedDayParts.map((dayPart) => ({
            day_part_id: dayPart.day_part_id,
            day_part_rank: dayPart.day_part_rank,
            notification_time: dayPart.notification_time,
            note: dayPart.note,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => ({
              food: foodItem.food,
              quantity: foodItem.quantity,
              recipe_link: foodItem.recipe_link,
              twins: foodItem.twins,
            })),
          })),
          reference_template_id: isNewDietPlanReferenced ? dietPlan.dietTemplate.id : null,
          referenced_from_user: isNewDietPlanReferenced ? selectedPatient.id : null,
        };
      }),
      guidelines: overAllGuidelines,
      title: dietPlans[0].dietTemplate.title,
    };
    setIsLoading(true);
    ApiClient.DietPlanUpdatePublish(id, pid, payload)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet Plan updated & published successfully');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(
          err.response.data?.errors?.non_field_errors || err.response.data?.errors?.diet_plans[0]?.non_field_errors
        );
      });
    mixpanel.track('Publishing of diet plan');
    mixpanel.people.set({
      $uuid: adminData.uuid,
      $name: adminData.full_name,
      $email: adminData.email,
      $phone: adminData.phone,
    });
  };

  const getDietIntensity = (profileInfo) => {
    const dietIntensityObject = profileInfo?.find((item) => item.key === 'diet_intensity');
    return dietIntensityObject ? dietIntensityObject.answer : null;
  };

  const getMedicalConditions = (profileInfo) => {
    const medicalConditionObject = profileInfo?.find((item) => item.key === 'medical_condition');
    return medicalConditionObject ? medicalConditionObject.answer : null;
  };

  const getFoodPreference = (profileInfo) => {
    const foodPreferenceObject = profileInfo?.find((item) => item.key === 'food_preference');
    return foodPreferenceObject ? foodPreferenceObject.answer : null;
  };
  const foodPreferenceAnswer = getFoodPreference(userDetails.profile_info);

  const getFoodAllergies = (profileInfo) => {
    const foodAllergiesObject = profileInfo?.find((item) => item.key === 'food_allergies');
    return foodAllergiesObject ? foodAllergiesObject.answer : null;
  };

  const FoodPreference = ({ foodPreferenceAnswer }) => {
    return (
      <span
        className="text-uppercase"
        style={{
          fontSize: '14px',
          backgroundColor: getBackgroundColor(foodPreferenceAnswer),
          color: getTextColor(foodPreferenceAnswer),
          padding: '5px 10px 5px 10px',
          borderRadius: '30px',
          fontWeight: '500',
        }}
      >
        {foodPreferenceAnswer}
      </span>
    );
  };

  const getBackgroundColor = (preference) => {
    switch (preference?.toLowerCase()) {
      case 'veg':
        return '#E8FFDD';
      case 'non-veg':
        return '#FFF6F3';
      case 'vegan':
        return '#0AB148';
      default:
        return '#D9D9D9';
    }
  };

  const getTextColor = (preference) => {
    switch (preference?.toLowerCase()) {
      case 'veg':
        return '#15803D';
      case 'non-veg':
        return '#B91C1C';
      case 'vegan':
        return '#FFFFFF';
      default:
        return '#333333';
    }
  };

  const HealthMetrics = () => {
    const dietIntensityAnswer = getDietIntensity(userDetails.profile_info);
    const medicalConditionAnswer = getMedicalConditions(userDetails.profile_info);
    const foodAllergyAnswer = getFoodAllergies(userDetails.profile_info);

    const getMetricAnswer = (key) => {
      const metric = metrics?.find((m) => m.key === key);
      return metric ? metric.answer : '';
    };

    const handleInputChange = (key, value) => {
      setMetrics((prevMetrics) =>
        prevMetrics?.map((metric) => (metric.key === key ? { ...metric, answer: value } : metric))
      );
    };

    return (
      <div className="metrix-main-div" style={{ padding: '0' }}>
        <div className="row col-md-12 card-row">
          {/* Metric Table Card */}
          <div className=" border-shadow col-md-4" style={{ width: '32.3%', marginRight: '10px' }}>
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="card-title mt-2" style={{ color: 'black', fontWeight: '500', fontSize: '12px' }}>
                <b style={{ fontSize: '15px' }}>{userDetails?.full_name}</b>{' '}
                {formatGenderAge(userDetails?.gender, userDetails?.profile_age)} | Intensity:{' '}
                {dietIntensityAnswer || Strings.NIL}
              </h6>

              <img
                src={blueEdit}
                onClick={() => {
                  setWeightEdit(true);
                }}
                className="editModal"
              />
            </div>
            <div className="mt-4 mb-4 table-responsive" style={{ borderRadius: '8px', overflow: 'hidden' }}>
              <table className="table metric-table">
                <thead>
                  <tr style={{ background: '#F3F5FC' }}>
                    <th>Metric</th>
                    <th>Current</th>
                    <th>Starting</th>
                    <th>Target</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Weight</td>
                    <td className="current-value">
                      <>
                        <Modal
                          backdrop="static"
                          size="md"
                          open={isWeightEdit}
                          onClose={() => {
                            getPatientData();
                            setWeightEdit(false);
                          }}
                        >
                          <Modal.Header>
                            <div className="settingFormOutline p-0">
                              <div className="formTitle mb-0" style={{ color: '#243B86' }}>
                                Edit Details
                              </div>
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="card-header d-flex justify-content-between align-items-center mb-4">
                              <h6 className="card-title" style={{ color: 'black', fontWeight: '500' }}>
                                <b>{userDetails?.full_name} </b>
                                {formatGenderAge(userDetails?.gender, userDetails?.profile_age)} | Intensity:{' '}
                                <div
                                  className="mt-2"
                                  style={{ display: 'inline-block', width: '150px', marginLeft: '5px' }}
                                >
                                  <CoreDropDown
                                    placeholder="Intensity"
                                    data={dietIntensityData}
                                    labelKey="title"
                                    valueKey="id"
                                    value={getMetricAnswer('diet_intensity') || ''}
                                    setValue={(inputVal) => {
                                      handleInputChange('diet_intensity', inputVal);
                                    }}
                                    inputStyle={{ border: '1px solid #D1DCFF', height: '30px', padding: '5px' }}
                                  />
                                </div>
                              </h6>
                            </div>
                            <CoreInputBox
                              validatedecimal
                              placeholder="weight"
                              label="Current Weight"
                              className="w-100"
                              setValue={(inputVal) => {
                                setWeightUpdatedByHealthCoach(inputVal);
                              }}
                              inputStyle={{ border: '1px solid #D1DCFF' }}
                            />
                            <h6 className="current-value ml-2 mt-2">BMI {userDetails?.current_bmi}</h6>

                            <div className="mt-4" style={{ float: 'right' }}>
                              <button
                                className="cancel"
                                onClick={() => {
                                  getPatientData();
                                  setWeightEdit(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="update"
                                onClick={() => {
                                  setWeightEdit(false);
                                  editCurrentWeightByHealthCoach();
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                      <div style={{ color: '#15803D' }}>
                        {userDetails?.current_weight ? `${userDetails?.current_weight} Kg` : ''}
                      </div>
                    </td>
                    <td>{userDetails?.starting_weight ? `${userDetails?.starting_weight} Kg` : ''}</td>
                    <td>{userDetails?.target_weight ? `${userDetails?.target_weight} Kg` : ''}</td>
                  </tr>
                  <tr>
                    <td>BMI</td>
                    <td className="current-value" style={{ color: '#15803D' }}>
                      {userDetails?.current_bmi}
                    </td>
                    <td>{userDetails?.starting_bmi}</td>
                    <td>{userDetails?.target_bmi}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Medical Conditions Card */}
          <div className="border-shadow col-md-4" style={{ width: '32.3%' }}>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="card-title mt-2">Medical Conditions</h6>
              <img
                src={blueEdit}
                onClick={() => {
                  setMedConEdit(true);
                }}
                className="editModal"
              />
            </div>
            <div className="card-body">
              <>
                <Modal
                  backdrop="static"
                  size="md"
                  open={isMedConEdit}
                  onClose={() => {
                    getPatientData();
                    setMedConEdit(false);
                  }}
                >
                  <Modal.Header>
                    <div className="settingFormOutline p-0">
                      <div className="formTitle mb-0" style={{ color: '#243B86' }}>
                        Edit Medical Conditions
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <CoreTextArea
                      type="text"
                      placeholder="medical Conditions"
                      label="Medical Conditions"
                      value={getMetricAnswer('medical_condition') || ''}
                      setValue={(inputVal) => {
                        handleInputChange('medical_condition', inputVal);
                      }}
                      handleKeyDown={(e) =>
                        handleTextFormatting(e, getMetricAnswer('medical_condition') || '', (updatedText) => {
                          handleInputChange('medical_condition', updatedText);
                        })
                      }
                      className="w-100 inputStyle"
                      inputStyle={{ border: '1px solid #D1DCFF' }}
                    />
                    <div className="mt-4" style={{ float: 'right' }}>
                      <button
                        className="cancel"
                        onClick={() => {
                          getPatientData();
                          setMedConEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="update"
                        onClick={() => {
                          setMedConEdit(false);
                          submitQuestionAnswers();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>

              <div className="mt-4">
                <CoreToggleText text={getMetricAnswer('medical_condition') || ''} wordLimit={30} />
              </div>
            </div>
          </div>
          {/* Food Preference Card */}
          <div className="border-shadow col-md-4" style={{ width: '32.3%', marginLeft: '10px' }}>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="card-title mt-2">Food Preference</h6>
              <div className="d-flex gap-2 mt-2">
                <FoodPreference foodPreferenceAnswer={foodPreferenceAnswer || Strings.NIL} />
                <img
                  src={blueEdit}
                  onClick={() => {
                    setFoodPefEdit(true);
                  }}
                  className="editModal"
                />
              </div>
            </div>
            {!foodPreferenceAnswer && (
              <span className="inputMsg" style={{ fontSize: '12px' }}>
                Please add veg/non-veg on profile questions
              </span>
            )}

            <div className="hrLine mt-2 mb-2" style={{ border: '1px dashed #D9D9D9', background: '#fff' }} />
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6
                  className="card-title mt-2"
                  style={{ fontWeight: '400', color: '#333333', textDecoration: 'underline' }}
                >
                  Food Allergies
                </h6>
              </div>

              <>
                <Modal
                  backdrop="static"
                  size="md"
                  open={isFoodPefEdit}
                  onClose={() => {
                    getPatientData();
                    setFoodPefEdit(false);
                  }}
                >
                  <Modal.Header>
                    <div className="settingFormOutline p-0">
                      <div className="formTitle mb-0" style={{ color: '#243B86' }}>
                        Edit Food Preferences
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="card-header d-flex justify-content-between align-items-center mb-4">
                      <h6 className="card-title" style={{ color: 'black', fontWeight: '400' }}>
                        Food Preference :{' '}
                        <div className="mt-2" style={{ display: 'inline-block', width: '170px', marginLeft: '5px' }}>
                          <CoreDropDown
                            placeholder="Food Preference"
                            data={foodPreferenceOptions}
                            labelKey="title"
                            valueKey="id"
                            value={getMetricAnswer('food_preference') || ''}
                            setValue={(inputVal) => {
                              handleInputChange('food_preference', inputVal);
                            }}
                            inputStyle={{ border: '1px solid #D1DCFF', height: '30px', padding: '5px' }}
                          />
                        </div>
                      </h6>
                    </div>

                    <CoreTextArea
                      type="text"
                      placeholder="Food Allergies"
                      label="Food Allergies"
                      value={getMetricAnswer('food_allergies') || ''}
                      setValue={(inputVal) => {
                        handleInputChange('food_allergies', inputVal);
                      }}
                      className="w-100"
                      inputStyle={{ border: '1px solid #D1DCFF' }}
                    />
                    <div className="mt-4" style={{ float: 'right' }}>
                      <button
                        className="cancel"
                        onClick={() => {
                          getPatientData();
                          setFoodPefEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="update"
                        onClick={() => {
                          setFoodPefEdit(false);
                          submitQuestionAnswers();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
              <CoreToggleText text={getMetricAnswer('food_allergies') || Strings.NIL} wordLimit={30} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const addNewDietPlan = () => {
    setDietPlans([...dietPlans, { templateValue: '', dietTemplate: {} }]);
    setSelectedPlanIndex(dietPlans.length);
  };

  const addNewFood = (dietPlanIndex, dayPartIndex) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };
      const currentDayPart = { ...currentDietPlan.dietTemplate.diet_plans[dayPartIndex] };

      const existingFoodItems = currentDayPart.diet_plan_food_items || [];
      const newFoodItem = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: String(
          existingFoodItems.length > 0 ? Number(existingFoodItems[existingFoodItems.length - 1].twins) + 1 : 1
        ),
      };

      currentDayPart.diet_plan_food_items = [...existingFoodItems, newFoodItem];
      currentDietPlan.dietTemplate.diet_plans[dayPartIndex] = currentDayPart;
      updatedDietPlans[dietPlanIndex] = currentDietPlan;

      return updatedDietPlans;
    });
  };

  const addInputBox = (dietPlanIndex, dayPartIndex, setIndex) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };
      const currentDayPart = { ...currentDietPlan.dietTemplate.diet_plans[dayPartIndex] };

      const currentFoodItems = [...currentDayPart.diet_plan_food_items];
      const currentSet = currentFoodItems[setIndex];

      const newSet = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: currentSet.twins,
      };

      currentFoodItems.splice(setIndex + 1, 0, newSet);

      currentDayPart.diet_plan_food_items = currentFoodItems;
      currentDietPlan.dietTemplate.diet_plans[dayPartIndex] = currentDayPart;
      updatedDietPlans[dietPlanIndex] = currentDietPlan;

      return updatedDietPlans;
    });
  };

  const handleDateRange = (dietPlanIndex, data) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];

      updatedDietPlans[dietPlanIndex].dietTemplate.start_date = data && data[0] ? new Date(data[0]) : null;
      updatedDietPlans[dietPlanIndex].dietTemplate.end_date = data && data[1] ? new Date(data[1]) : null;

      return updatedDietPlans;
    });
  };

  const handleClean = (dietPlanIndex) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      updatedDietPlans[dietPlanIndex].dietTemplate.start_date = null;
      updatedDietPlans[dietPlanIndex].dietTemplate.end_date = null;

      return updatedDietPlans;
    });
  };

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handleSelectionChange = (dietPlanIndex, selectedDayIndices) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      updatedDietPlans[dietPlanIndex].dietTemplate.week_days = selectedDayIndices;
      return updatedDietPlans;
    });
  };

  const removeInputBox = (dietPlanIndex, dayPartIndex, setIndex) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };
      const currentDayPart = { ...currentDietPlan.dietTemplate.diet_plans[dayPartIndex] };

      currentDayPart.diet_plan_food_items.splice(setIndex, 1);
      currentDietPlan.dietTemplate.diet_plans[dayPartIndex] = currentDayPart;
      updatedDietPlans[dietPlanIndex] = currentDietPlan;

      return updatedDietPlans;
    });
  };

  const handleChange = (dietPlanIndex, dayPartIndex, setIndex, field, data) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };
      const currentDayPart = { ...currentDietPlan.dietTemplate.diet_plans[dayPartIndex] };

      currentDayPart.diet_plan_food_items[setIndex][field] = data;
      currentDietPlan.dietTemplate.diet_plans[dayPartIndex] = currentDayPart;
      updatedDietPlans[dietPlanIndex] = currentDietPlan;

      return updatedDietPlans;
    });
  };

  const removeFormFieldForDiet = (dietPlanIndex, dayPartIndex) => {
    const windowConfirm = window.confirm('Remove Day Part');
    if (windowConfirm) {
      setDietPlans((prevDietPlans) => {
        const updatedDietPlans = [...prevDietPlans];
        const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };

        currentDietPlan.dietTemplate.diet_plans.splice(dayPartIndex, 1);
        updatedDietPlans[dietPlanIndex] = currentDietPlan;

        return updatedDietPlans;
      });

      if (setErrors && errors?.diet_plans) {
        const newErrors = { ...errors };
        newErrors.diet_plans[dietPlanIndex].splice(dayPartIndex, 1);
        setErrors(newErrors);
      }
    }
  };

  const addDayPartForDiet = (dietPlanIndex) => {
    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = [...prevDietPlans];
      const currentDietPlan = { ...updatedDietPlans[dietPlanIndex] };

      if (!currentDietPlan.dietTemplate.diet_plans) {
        currentDietPlan.dietTemplate.diet_plans = [];
      }
      currentDietPlan.dietTemplate.diet_plans.push({
        day_part_id: '',
        day_part_rank: '',
        notification_time: '',
        diet_plan_food_items: [
          {
            food: '',
            quantity: '',
            recipe_link: '',
            twins: '1',
          },
        ],
      });
      updatedDietPlans[dietPlanIndex] = currentDietPlan;
      return updatedDietPlans;
    });
  };

  const deleteDietPlan = (indexToDelete) => {
    if (dietPlans.length <= 1) {
      setIsShowAlert("Can't delete the only diet plan");
      setAlertType('alert-danger');
      return;
    }

    setDietPlans((prevDietPlans) => {
      const updatedDietPlans = prevDietPlans.filter((_, index) => index !== indexToDelete);
      if (selectedPlanIndex === indexToDelete) {
        setSelectedPlanIndex(0);
      } else if (selectedPlanIndex > indexToDelete) {
        setSelectedPlanIndex(selectedPlanIndex - 1);
      }
      return updatedDietPlans;
    });

    setIsShowAlert('Diet plan deleted successfully');
    setAlertType('alert-success');
  };

  let userDetail = localStorage.getItem('userDetails');
  userDetail = JSON.parse(userDetail);
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetail.gender, userDetail.image),
      title: userDetail.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + 'Diet Plan', link: '' },
  ];
  const showPatient = () => (
    <>
      <div className="articleHeader stickyHeader">
        <div className="headerFiles">
          <AdminPanelHeader iconTitle="person_outline" showTree={showTree} title="Patient" />
        </div>

        {/* <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Diet Plan</div>
        <div className='hrLine' /> */}
      </div>
      <div className="px-4 wrapperContent">
        {isShowAlert && (
          <div className="mt-5">
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}

        {HealthMetrics()}
        <div className="diet-plans-container">
          {dietPlans?.map((dietPlan, index) => (
            <div key={index} className="diet-plan">
              <AddDietplanForm
                dietPlanIndex={index}
                errors={errors}
                setErrors={setErrors}
                dietPlan={dietPlan.dietTemplate}
                setDietPlan={(template) => {
                  const updatedDietPlans = [...dietPlans];
                  updatedDietPlans[index].dietTemplate = template;
                  setDietPlans(updatedDietPlans);
                }}
                templateList={templateList}
                setTemplateList={setTemplateList}
                templateValue={dietPlan.templateValue}
                setTemplateValue={(value) => {
                  const updatedDietPlans = [...dietPlans];
                  updatedDietPlans[index].templateValue = value;
                  getTemplateQuestion(index);
                  setDietPlans(updatedDietPlans);
                }}
                adminData={adminData}
                patientSearchStr={patientSearchStr}
                setPatientSearchStr={setPatientSearchStr}
                searchPatients={searchPatients}
                searchPatientsData={searchPatientsData}
                setSearchPatientsData={setSearchPatientsData}
                handleSelectPatient={handleSelectPatient}
                isNewDietPlanReferenced={isNewDietPlanReferenced}
                setIsNewDietPlanReferenced={setIsNewDietPlanReferenced}
                userPublishedDietPlans={userPublishedDietPlans}
                setUserPublishedDietPlans={setUserPublishedDietPlans}
                searchTemplateList={searchTemplateList}
                setSearchTemplateList={setSearchTemplateList}
                searchDietTemplate={searchDietTemplate}
                setIsLoading={setIsLoading}
                pid={pid}
                id={id}
                addNewFood={(dayPartIndex) => addNewFood(index, dayPartIndex)}
                addInputBox={(dayPartIndex, setIndex) => addInputBox(index, dayPartIndex, setIndex)}
                handleSelectionChange={(selectedDaysIndices) => handleSelectionChange(index, selectedDaysIndices)}
                removeInputBox={(dayPartIndex, setIndex) => removeInputBox(index, dayPartIndex, setIndex)}
                handleChange={(dayPartIndex, setIndex, field, data) =>
                  handleChange(index, dayPartIndex, setIndex, field, data)
                }
                removeFormFieldForDiet={(dayPartIndex) => removeFormFieldForDiet(index, dayPartIndex)}
                addDayPartForDiet={() => addDayPartForDiet(index)}
                handleDateRange={(data) => handleDateRange(index, data)}
                handleClean={() => handleClean(index)}
                days={days}
                totalDietPlans={dietPlans.length}
                deleteDietPlan={deleteDietPlan}
                // weekDays={dietPlan.dietTemplate.week_days}
                overAllGuidelines={overAllGuidelines}
                setOverAllGuidelines={setOverAllGuidelines}
              />
            </div>
          ))}
        </div>
        <div className="mt-2">
          <button
            className="border-shadow full-width-button "
            style={{ padding: '14px 14px 14px 50px', background: '#fff' }}
            onClick={addNewDietPlan}
          >
            + Add Another Diet Plan
          </button>
        </div>

        <div className="d-flex justify-content-end me-4" style={{ alignItems: 'center', gap: '10px' }}>
          <button
            type="button"
            className="save-details"
            onClick={() => {
              pid ? updateDiet() : createDiet();
            }}
          >
            {pid ? 'Update' : 'Save'} Details
          </button>
          <button
            type="button"
            className="core-btn-form"
            onClick={() => {
              pid ? updatePublishDiet() : createPublishDiet();
            }}
          >
            {pid ? 'Update' : 'Save'} & Publish
          </button>
        </div>
      </div>
    </>
  );
  return (
    <div className="wrapper">
      {/* {sideBar} */}
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddDietPlan;
