import React from "react";
import {
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
} from "whealth-core-web/components";
import { binIcon } from "res/images";
import { CircularProgress } from "@mui/material";

const VariationCard = ({
  index,
  formData,
  handleInputChange,
  handleVariationChange,
  getFormulationData,
  variationInputStyles,
  inputStyles,
  addNewMedicineErrorMsg,
  searchMedicine,
  showSearchDropdown,
  setShowSearchDropdown,
  isSearching,
  searchError,
  searchResults,
  handleSearchResultSelect,
  handleCheckboxChange,
  removeVariation,
}) => {
  const variation = formData?.product?.product_line_items[index];
  const isPrimaryDisabled = formData?.product?.product_line_items?.some(
    (item, i) => i !== index && item?.active
  );
  const selectedVariationIds = formData?.product?.product_line_items
    .filter((_, i) => i !== index)
    .map((item) => item.variation.id);

  const availableFormulations = getFormulationData.filter(
    (item) => !selectedVariationIds.includes(item.id)
  );
  availableFormulations.push({
    id: variation?.variation?.id,
    name: variation?.variation?.pack_form + " of " + variation?.variation?.type,
    variation: variation?.variation?.variation,
    type: variation?.variation?.type,
  });

  const styles = {
    searchContainer: {
      position: "relative",
      width: "321px",
    },
    searchDropdown: {
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      background: "white",
      border: "1px solid #D9D9D9",
      borderRadius: "5px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      zIndex: 1000,
    },
    searchLoading: {
      padding: "12px",
      textAlign: "center",
      color: "#666",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
    },
    searchError: {
      padding: "12px",
      textAlign: "center",
      color: "#AB1315",
    },
    noResults: {
      padding: "12px",
      textAlign: "center",
      color: "#666",
      cursor: "pointer",
    },
    searchResultsList: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    searchResultItem: {
      padding: "8px 12px",
      cursor: "pointer",
      borderBottom: "1px solid #f0f0f0",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    resultName: {
      fontWeight: 500,
      fontSize: "14px",
    },
  };

  return (
    <div className="my-4">
      <div className="variationCardHeader">
        <div className="variationCardTitle">Variation {index + 1}</div>
        {formData.product.product_line_items.length > 1 && (
          <img
            className="deleteIconStyle"
            src={binIcon}
            width={20}
            height={20}
            onClick={() => removeVariation(index)}
          />
        )}
      </div>
      <div className="labelStyle">Search Medicine</div>
      <div className="search-container" style={styles.searchContainer}>
        <CoreInputBox
          placeholder="Search Medicine (min. 3 characters)"
          type="text"
          value={
            formData?.product?.product_line_items?.[index]?.product_name || ""
          }
          setValue={(data) => {
            handleInputChange(
              "product.product_line_items.product_name",
              data,
              index
            );
            searchMedicine(data, index);
          }}
          inputStyle={variationInputStyles}
          msgStyle={{ color: "red" }}
          showMSG={addNewMedicineErrorMsg?.sku}
        />

        {showSearchDropdown[index] && (
          <div style={styles.searchDropdown}>
            {isSearching && (
              <div style={styles.searchLoading}>
                <CircularProgress size={20} />
                <span>Searching...</span>
              </div>
            )}

            {searchError && <div style={styles.searchError}>{searchError}</div>}

            {!isSearching && !searchError && searchResults.length === 0 && (
              <div
                style={styles.noResults}
                onClick={() => {
                  setShowSearchDropdown((p) => {
                    const newState = [...p];
                    newState[index] = false;
                    return newState;
                  });
                }}
              >
                Add new medicine{" : "}
                {formData?.product?.product_line_items?.[index]?.product_name}
              </div>
            )}

            {!isSearching && !searchError && searchResults.length > 0 && (
              <ul style={styles.searchResultsList}>
                {searchResults.map((result, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleSearchResultSelect(result, index)}
                    style={styles.searchResultItem}
                  >
                    <div style={styles.resultName}>
                      {result?.product?.product_name}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>

      <>
        <div className="labelStyle">
          Variation <span style={{ color: "#AB1315" }}>*</span>
        </div>
        <CoreDropDown
          placeholder="Select Variation"
          selectStyle={{
            width: "321px",
            height: "36px",
            borderRadius: "5.03px",
            border: "#D9D9D9 0.75px solid",
          }}
          retuired={false}
          showMSG={addNewMedicineErrorMsg?.variation}
          data={availableFormulations}
          showKey="name"
          setKey="id"
          disabled={variation?.mrp}
          value={variation?.variation?.id || ""}
          setValue={(value) => handleVariationChange(index, value)}
          labelStyle={{ fontWeight: "var(--lightFont)" }}
          msgStyle={{ color: "red" }}
        />
      </>

      <div className="labelStyle">
        MRP <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <CoreInputBox
        placeholder="₹ 0.00"
        type="number"
        value={"₹ " + variation?.mrp}
        disabled={formData?.product?.product_line_items?.[index]?.sku?.includes(
          "1mg"
        )}
        setValue={(data) => {
          const numericValue = data
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/^(\d*\.\d{0,2}).*$/, "$1");
          handleInputChange(
            "product.product_line_items.mrp",
            numericValue,
            index
          );
        }}
        inputStyle={variationInputStyles}
        validateNumbers={variation?.mrp == "int"}
        validatedecimal={variation?.mrp == "float"}
        validateZero={variation?.mrp == "int"}
        msgStyle={{ color: "red" }}
        showMSG={addNewMedicineErrorMsg[`mrp_${index}`]}
      />
      <div className="d-flex">
        <CoreCheckBox
          title={"Primary"}
          checked={variation.active}
          onChange={() => handleCheckboxChange("active", index)}
          className="manualCheckox"
          disabled={
            formData?.product?.product_line_items?.length === 1 &&
            !formData?.editAddFromList
              ? true
              : isPrimaryDisabled && !variation.active
          }
        />
        <CoreCheckBox
          title={"Out of Stock"}
          checked={variation.oos}
          onChange={() => handleCheckboxChange("oos", index)}
          className="manualCheckox"
        />
      </div>
      <div className="labelStyle">Manufacturer</div>
      <CoreInputBox
        value={formData?.product?.product_line_items?.[0]?.manufacturer?.name}
        setValue={(data) => handleInputChange("manufacturer", data)}
        inputStyle={inputStyles}
        msgStyle={{ color: "red" }}
        disabled={true}
      />
    </div>
  );
};

export default VariationCard;
