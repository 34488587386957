import React, { useEffect, useState } from 'react';
import { CloseRound } from 'res/images';
import { CoreSearchDropDown } from 'whealth-core-web/components';
import useApiManager from 'networking/ApiManager';
import { useParams } from 'react-router-dom';
import { profileImage, teamRoles } from 'whealth-core-web/components/Helper';
import { useDispatch } from 'react-redux';
import { setAlerts, setDoctorData, updatePatientDetailsApi } from 'redux/Slices';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import { Button, Modal } from 'rsuite';

function AssignteamCard(props) {
  const { userData, inputValue, setInputValue, placeholder, searchRole, getPatientData } = props;
  const ApiClient = useApiManager();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [showModal, setShowModal] = useState({
    showModal: false,
    user: null,
  });

  useEffect(() => {
    setAdminData(userData.admins);
  }, [userData]);

  const updatePatientDetails = () => {
    dispatch(updatePatientDetailsApi());
  };

  const searchAdminProfiles = () => {
    let qry = {
      role: searchRole || 'health_coach,health_coach_lead,admin,diet_health_coach,fitness_health_coach',
      search_str: inputValue,
    };
    ApiClient.getSearchTeam(qry)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleItemClick = (item) => {
    setInputValue('');
    let data = { admin_id: item.id };
    ApiClient.assingAdmin(id, data)
      .then((res) => {
        setAdminData(res.data.admins);
        dispatch(setDoctorData(res.data.admins));
        getPatientData();
        // updatePatientDetails();
      })
      .catch((err) => {
        console.warn('err', err.response.data);
        if (err.response?.data?.errors?.admins) {
          dispatch(setAlerts({ patient: { title: err.response?.data?.errors?.admins, type: 'alert-danger' } }));
        }
      });
    setData([]);
  };

  const confirmRemoveUser = (item) => {
    setShowModal({
      showModal: true,
      user: item,
    });
  };

  const removeUser = (itemId) => {
    let data = { admin_id: itemId };
    setShowModal({
      showModal: false,
      user: null,
    });
    ApiClient.unasignAdmin(id, data)
      .then((res) => {
        setAdminData(res.data.admins);
        getPatientData();
        // updatePatientDetails();
      })
      .catch((err) => {
        if (!Array.isArray(Object.values(err.response.data)[0])) {
          dispatch(setAlerts({ patient: { title: Object.values(err.response.data)[0], type: 'alert-danger' } }));
        }
      });
  };

  const renderInputBox = () => {
    return (
      <>
        <CoreSearchDropDown
          setTimeOutData={searchAdminProfiles}
          {...props}
          inputValue={inputValue}
          inputStyle={{ height: '39px' }}
          data={data}
          setData={setData}
          setInputValue={setInputValue}
          placeholder={placeholder || ''}
          onClickItem={handleItemClick}
          dropDownStyle={{ left: '10px', right: '10px' }}
          isRightIconref
        />
        {inputValue.length > 2 && data.length == [] && (
          <div className="mt-2" style={{ textAlign: 'center' }}>
            No Data Found
          </div>
        )}
      </>
    );
  };

  const showUserDetails = () => {
    if (adminData?.length > 0) {
      return adminData.map((item) => {
        return (
          <div className="assignPatient">
            <img
              className="rounded assignedPatientImage float-start img-fluid me-3"
              src={profileImage(item.gender, item.profile_url)}
            />
            <div className="font-10px">
              <div className="capitalize bold">{teamRoles(item.role)}</div>
              <div className="capitalize">{item.full_name}</div>
            </div>
            <div className="close-icon" onClick={() => confirmRemoveUser(item)}>
              <CommonToolTip placement={'top'} item={<img src={CloseRound} className="icon" />} showValue={'Remove'} />
            </div>
          </div>
        );
      });
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const createConfirmationModal = () => {
    return (
      <Modal backdrop="static" keyboard={false} open={showModal.showModal} onClose={hideModal}>
        <Modal.Header>
          <Modal.Title>
            <h5>Warning!</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 16 }}>Are you sure you want to remove this {teamRoles(showModal?.user?.role)}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => removeUser(showModal?.user?.id)}
            appearance="primary"
            style={{ backgroundColor: '#243B86' }}
          >
            Remove
          </Button>
          <Button onClick={hideModal} appearance="default">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="d-md-flex mb-3">
      <div className="card w-100 no-border">
        <div className="card-body row">{showUserDetails()}</div>
        {createConfirmationModal()}
        <div className="linesView">
          <div className="assign-text-box m-2">{renderInputBox()}</div>
        </div>
      </div>
    </div>
  );
}

export default AssignteamCard;
