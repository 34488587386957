import React, { useCallback, useEffect, useState } from "react";
import { closeIcon } from "res/images";
import { CommonAlert } from "whealth-core-web/components";
import "./filterModal.scss";
import "./ManageInventoryActionModal.scss";
import useApiManager from "networking/ApiManager";
import AddNewMedicine from "whealth-core-web/components/Orders/AddNewMedicine";
import EditMedicine from "whealth-core-web/components/Orders/EditMedicine";
import VariationCard from "whealth-core-web/components/Orders/VariationCard";
import useFormulation from "whealth-core-web/hooks/useFormulation";
import { debounce } from "whealth-core-web/forms/AddPrescriptionForm";

function ManageInventoryActionModal(props) {
  const {
    isAddAction,
    selectedRowDetails,
    setIsSuccess,
    setToastMsg,
    isItPostReq,
    setIsInventoryActionModalOpen,
    setIsError,
  } = props;
  const ApiClient = useApiManager();
  const initialState = {
    id: "",
    name: "",
    formulation: "",
    status: "Published",
    product: {
      product_line_items: [
        {
          id: "",
          sku: "",
          variation: { id: "", name: "", variation: "", type: "" },
          manufacturer: { id: 1, name: "ABC" },
          quantity_available: 0,
          price: "",
          mrp: "",
          active: true,
          oos: false,
        },
      ],
    },
    editAddFromList: false,
    isActiveVariant: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [addNewMedicineErrorMsg, setAddNewMedicineErrorMsg] = useState({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("alert-danger");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [showSearchDropdown, setShowSearchDropdown] = useState([]);

  const getFormulationData = useFormulation(
    formData?.formulation,
    selectedRowDetails
  );

  const postInventoryMedicines = async (data, index) => {
    ApiClient.postInventoryMedicines(data)
      .then(() => {
        setIsSuccess(true);
        setIsError(false);

        const firstVariation =
          formData?.product?.product_line_items?.[0]?.variation;
        const additionalVariations =
          (formData?.product?.product_line_items?.length || 0) - 1;

        let toastMessage = `${formData?.name} - ${firstVariation?.name}, ${firstVariation?.variation}, ${firstVariation?.type} added successfully.`;

        if (additionalVariations > 0) {
          toastMessage += ` +${additionalVariations} more variation${
            additionalVariations > 1 ? "s" : ""
          }`;
        }

        setToastMsg(toastMessage);
        setFormData();
        setIsInventoryActionModalOpen(false);
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsError(true);
        console.error("Error adding medicine:", error);
        setIsInventoryActionModalOpen(false);
        if (error?.response?.data?.non_field_errors) {
          const errorMessage = error.response.data.non_field_errors[0];
          setIsShowAlert(true);
          setAlertType("alert-danger");
          setToastMsg(errorMessage);
        } else if (error?.response?.data?.errors) {
          setToastMsg("An error occurred. Please check the form for details.");
        } else if (
          error.response &&
          (error.response.status === 400 || error.response.status === 422)
        ) {
          setToastMsg("Medicine with same name and formulation already exists");
        } else {
          setToastMsg("An unexpected error occurred.");
        }
      });
  };

  const updateInventoryMedicines = async (id, data) => {
    ApiClient.updateInventoryMedicines(formData?.id, formData)
      .then(() => {
        const lineItem = formData?.product?.product_line_items?.[0];
        setIsSuccess(true);
        setIsError(false);
        setToastMsg(
          `${formData?.name} ${lineItem?.variation?.name}, ${lineItem?.variation?.variation}, ${lineItem?.variation?.type} - ${lineItem?.sku} updated successfully`
        );
        setIsInventoryActionModalOpen(false);
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsError(true);
        console.error("Error updating medicine:", error);
        setIsInventoryActionModalOpen(false);
        setToastMsg(`Error: ${error}`);
        if (error?.response?.data?.non_field_errors) {
          const errorMessage = error.response.data.non_field_errors[0];
          setIsShowAlert(true);
          setAlertType("alert-danger");
          setToastMsg(errorMessage);
        } else if (error?.response?.data?.errors) {
          setToastMsg("An error occurred. Please check the form for details.");
        } else {
          setToastMsg("An unexpected error occurred.");
        }
      });
  };

  const handleStatusChange = (value, index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        product_line_items: prevData.product.product_line_items.map((item, i) =>
          i === index ? { ...item, status: value } : item
        ),
      },
    }));
  };

  const handleInputChange = (field, value, index) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (field.includes("product.product_line_items")) {
        const [, , nestedField, subField] = field.split(".");
        if (nestedField === "manufacturer" && subField === "name") {
          newData.product.product_line_items[index].manufacturer = {
            ...newData.product.product_line_items[index].manufacturer,
            name: value,
          };
        } else if (nestedField === "variation") {
          newData.product.product_line_items[index].variation = {
            ...newData.product.product_line_items[index].variation,
            [subField]: value,
          };
        } else {
          newData.product.product_line_items[index][nestedField] = value;
        }
      } else {
        newData[field] = value;
      }
      return newData;
    });
  };

  const handleVariationChange = (index, value) => {
    const selectedVariation = getFormulationData.find(
      (item) => item.id == value
    );
    console.log("selected variation", value, selectedVariation);

    if (selectedVariation) {
      const [name, variation, type] = selectedVariation.name.split(", ");
      setFormData((prevData) => ({
        ...prevData,
        product: {
          ...prevData.product,
          product_line_items: prevData.product.product_line_items.map(
            (item, i) =>
              i === index
                ? {
                    ...item,
                    variation: {
                      id: selectedVariation.id,
                      name: name,
                      variation: variation,
                      type: type,
                      pack_form: selectedVariation.pack_form,
                    },
                  }
                : item
          ),
        },
      }));
    }
  };

  const handleSubmit = (event) => {
    console.log("handle submit");
    event.preventDefault();
    let hasError = false;
    let primaryVariationError = formData?.editAddFromList ? false : true;

    formData?.product?.product_line_items?.forEach((item, index) => {
      if (!item?.variation?.id) {
        setAddNewMedicineErrorMsg((prev) => ({
          ...prev,
          [`variation_${index}`]: "Select a Variation to continue.",
        }));
        hasError = true;
      }

      if (!item.mrp && parseFloat(item.mrp) <= 0) {
        setAddNewMedicineErrorMsg((prev) => ({
          ...prev,
          [`mrp_${index}`]: "Please enter a valid MRP.",
        }));
        hasError = true;
      }
      if (item.active) {
        primaryVariationError = false;
      }
    });

    if (!formData.name && !selectedRowDetails?.name) {
      setAddNewMedicineErrorMsg((prev) => ({
        ...prev,
        medicineName: "Enter Medicine Name to continue.",
      }));
      hasError = true;
    }

    if (!formData.formulation) {
      setAddNewMedicineErrorMsg((prev) => ({
        ...prev,
        formulation: "Enter a Formulation to continue.",
      }));
      hasError = true;
    }

    if (primaryVariationError && isAddAction) {
      setAddNewMedicineErrorMsg((prev) => ({
        ...prev,
        primaryError:
          "Please ensure at least one medicine is marked as primary.",
      }));
      hasError = true;
    }

    if (!hasError) {
      // Transform the data to match the expected payload format
      const transformedData = {
        id: formData.id,
        name: formData.name,
        formulation: formData.formulation,
        editAddFromList: formData.editAddFromList || true,
        isActiveVariant: formData.isActiveVariant || true,
        product: {
          product_line_items: formData.product.product_line_items.map(
            (item) => {
              console.log("huzz item 2", item);
              return {
                sku: item.sku,
                product_line_item_id: item.product_line_item_id,
                variation: {
                  id: item.variation.id,
                  name: item.variation.name,
                  variation: item.variation.variation,
                  type: item.variation.type,
                },
                manufacturer: {
                  id: item?.manufacturer?.id,
                  name: item?.manufacturer?.name,
                },
                quantity_available: item.quantity_available || 0,
                price: item.price || "0",
                mrp: item.mrp,
                active: item.active,
                oos: item.oos,
                is_variation_available: true,
                changed: item.changed || true,
              };
            }
          ),
        },
      };
      console.log("handle submit");
      if (isAddAction) {
        console.log("post medicine with transformed data", transformedData);
        postInventoryMedicines(transformedData);
      } else {
        console.log("update medicine", transformedData);
        updateInventoryMedicines(transformedData.id, transformedData);
      }
    }
  };

  const handleCheckboxChange = (field, index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        product_line_items: prevData.product.product_line_items.map((item, i) =>
          i === index ? { ...item, [field]: !item[field] } : item
        ),
      },
    }));
  };

  const filterHeader = () => {
    return (
      <div
        className="filterHeader"
        style={!isAddAction ? { marginTop: "-100px" } : {}}
      >
        {isShowAlert && (
          <div className="mt-2">
            <CommonAlert
              isShowAlert={isShowAlert}
              alertType={alertType}
              setIsShowAlert={setIsShowAlert}
            />
          </div>
        )}
        <div>{isAddAction ? "Add New Medicine" : "Edit Medicine"}</div>
        <div
          className="closeIconStyle"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setFormData(initialState);
            setAddNewMedicineErrorMsg({});
            props.onClose(); // Add this line
          }}
        >
          <img src={closeIcon} width={17.78} height={17.78} />
        </div>
      </div>
    );
  };

  const addVariation = () => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        product_line_items: [
          ...prevData.product.product_line_items,
          {
            sku: "",
            variation: { id: "", name: "", variation: "", type: "" },
            manufacturer: { id: 1, name: "abc" },
            quantity_available: 0,
            price: "",
            mrp: "",
            status: "",
            active: false,
            oos: false,
          },
        ],
      },
    }));
  };

  const removeVariation = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        product_line_items: prevData.product.product_line_items.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const searchMedicine = useCallback((query, index) => {
    if (!query || query.length < 3) {
      setShowSearchDropdown((prev) => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    setSearchError(null);
    setShowSearchDropdown((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });

    ApiClient.search("PRODUCTLINEITEMSEARCH", { search_str: query })
      .then((response) => {
        const results = response?.data || [];
        setSearchResults(results);
        setIsSearching(false);
      })
      .catch((error) => {
        console.error("Error searching medicine:", error);
        setSearchError("Failed to fetch search results");
        setIsSearching(false);
      });
  }, []);
  const debouncedSearchMedicine = useCallback(
    debounce(searchMedicine, 500),
    []
  );
  console.log("huzz formData", formData.product.product_line_items);
  const handleSearchResultSelect = (selectedItem, index) => {
    console.log("huzz selectedItem on select", selectedItem);
    setFormData((prevData) => {
      console.log("huzz selectedItem", selectedItem);
      return {
        ...prevData,
        name: selectedItem.name || "",
        // formulation: selectedItem.product?.medicine?.formulation || "",
        product: {
          ...prevData.product,
          product_line_items: prevData.product.product_line_items.map(
            (item, i) => {
              console.log("huzz item", item);
              return i === index
                ? {
                    ...item,
                    product_line_item_id: selectedItem.id,
                    product_name: selectedItem.product?.product_name || "",
                    mrp: selectedItem?.product_price?.[0]?.mrp || "",
                    manufacturer: selectedItem.manufacturer || {
                      id: 1,
                      name: "ABC",
                    },
                    variation: selectedItem.variation || {
                      id: "",
                      name: "",
                      variation: "",
                      type: "",
                    },
                  }
                : item;
            }
          ),
        },
      };
    });
    setShowSearchDropdown((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  const filterButtons = () => {
    return (
      <div className="footerButtons">
        <button type="submit" className="button applyBtn">
          {isAddAction ? "Add Medicine" : "Update Medicine"}
        </button>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="renderFooter">
        {addNewMedicineErrorMsg?.primaryError && (
          <div className="inputMsg">{addNewMedicineErrorMsg?.primaryError}</div>
        )}
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const renderModal = (index) => {
    return (
      <div className="filterModal">
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="whiteBackground">
            {filterHeader()}
            <form onSubmit={handleSubmit}>
              {isAddAction ? (
                <AddNewMedicine
                  selectedRowDetails={selectedRowDetails}
                  formData={formData}
                  handleInputChange={handleInputChange}
                  inputStyles={inputStyles}
                  addNewMedicineErrorMsg={addNewMedicineErrorMsg}
                  isItPostReq={isItPostReq}
                  setFormData={setFormData}
                  VariationCard={(index) => (
                    <VariationCard
                      key={index}
                      index={index}
                      formData={formData}
                      handleInputChange={handleInputChange}
                      handleVariationChange={handleVariationChange}
                      getFormulationData={getFormulationData}
                      variationInputStyles={variationInputStyles}
                      inputStyles={inputStyles}
                      addNewMedicineErrorMsg={addNewMedicineErrorMsg}
                      searchMedicine={debouncedSearchMedicine}
                      showSearchDropdown={showSearchDropdown}
                      setShowSearchDropdown={setShowSearchDropdown}
                      isSearching={isSearching}
                      searchError={searchError}
                      searchResults={searchResults}
                      handleSearchResultSelect={handleSearchResultSelect}
                      handleCheckboxChange={handleCheckboxChange}
                      removeVariation={removeVariation}
                    />
                  )}
                  addVariation={addVariation}
                />
              ) : (
                <EditMedicine
                  formData={formData}
                  getFormulationData={getFormulationData}
                  handleCheckboxChange={handleCheckboxChange}
                  handleVariationChange={handleVariationChange}
                  handleInputChange={handleInputChange}
                  inputStyles={inputStyles}
                  addNewMedicineErrorMsg={addNewMedicineErrorMsg}
                  index={0}
                />
              )}
              {renderFooter()}
            </form>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedRowDetails) {
      setFormData({
        ...initialState,
        id: selectedRowDetails.id,
        name: selectedRowDetails.name,
        formulation: selectedRowDetails.formulation,
        status: selectedRowDetails.status || "Published",
        product: {
          product_line_items: selectedRowDetails.product?.product_line_items
            ?.length
            ? selectedRowDetails.product.product_line_items
            : initialState.product.product_line_items,
        },
        editAddFromList: selectedRowDetails.editAddFromList || false,
        isActiveVariant: selectedRowDetails.isActiveVariant || false,
      });
    }
  }, [selectedRowDetails]);

  return renderModal();
}

export default ManageInventoryActionModal;

const inputStyles = {
  color: "#333333",
  fontSize: "12px",
  border: "0.75px solid #D9D9D9",
  padding: "5px",
  width: "345px",
  height: "36px",
  borderRadius: "5.03px",
  // marginBottom: '20px',
  display: "flex",
  alignItems: "center",
};

const variationInputStyles = {
  color: "#333333",
  fontSize: "12px",
  border: "0.75px solid #D9D9D9",
  padding: "5px",
  width: "321px",
  height: "36px",
  borderRadius: "5.03px",
  marginBottom: "5px",
  display: "flex",
  alignItems: "center",
};

const searchDropdownStyles = `
.search-container {
  position: relative;
  width: 321px;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.search-loading,
.search-error,
.no-results {
  padding: 12px;
text-align: center;
  color: #666;
}

.search-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.search-error {
  color: #AB1315;
}

.search-results-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-result-item {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.search-result-item:hover {
  background-color: #f5f5f5;
}

.search-result-item:last-child {
  border-bottom: none;
}

.result-name {
  font-weight: 500;
  font-size: 14px;
}

.result-details {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}
`;
