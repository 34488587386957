import React from "react";
import {
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
} from "whealth-core-web/components";

const EditMedicine = ({
  formData,
  getFormulationData,
  handleCheckboxChange,
  handleVariationChange,
  handleInputChange,
  inputStyles,
  addNewMedicineErrorMsg,
  index = 0,
}) => {
  const finalFormulation = [...getFormulationData];
  finalFormulation.push({
    name:
      formData?.product?.product_line_items[index]?.variation?.pack_form +
      " of " +
      formData?.product?.product_line_items[index]?.variation?.type,
    id: formData?.product?.product_line_items[index]?.variation?.id,
    pack_form:
      formData?.product?.product_line_items[index]?.variation?.pack_form,
    type: formData?.product?.product_line_items[index]?.variation?.type,
  });

  return (
    <div style={{ marginTop: "-100px" }}>
      <div className="skuDiv">
        SKU: {formData?.product?.product_line_items[index]?.sku}
      </div>
      <div className="labelStyle mt-4">
        Name <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <div className="inputDetailsDivInventory disabled">{formData?.name}</div>
      <div className="labelStyle mt-4">
        Formulation <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <div
        className="inputDetailsDivInventory disabled mb-4"
        style={{ textTransform: "capitalize" }}
      >
        {formData?.formulation}
      </div>
      <div
        className="checkboxContainer"
        style={{ marginBottom: "8px", marginTop: "10px", marginLeft: "2px" }}
      >
        <CoreCheckBox
          checked={formData?.product?.product_line_items[index]?.active}
          title={"Primary"}
          onChange={() => handleCheckboxChange("active", index)}
          className="manualCheckox"
          style={{ fontWeight: "500" }}
          disabled={formData?.isActiveVariant}
        />
      </div>
      <div className="labelStyle">Manufacturer</div>
      <div
        className="inputDetailsDivInventory"
        disabled
        style={{ backgroundColor: "#e9ecef" }}
      >
        {formData?.product?.product_line_items[index]?.manufacturer?.name}
      </div>
      <div className="labelStyle mt-4">
        Variation <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <CoreDropDown
        placeholder="Select Variation"
        className="mt-4"
        selectStyle={{
          width: "345px",
          height: "36px",
          borderRadius: "5.03px",
          border: "#D9D9D9 0.75px solid",
        }}
        retuired={true}
        showMSG={addNewMedicineErrorMsg?.variation}
        data={finalFormulation}
        showKey="name"
        setKey="id"
        value={formData?.product?.product_line_items[index]?.variation?.id}
        setValue={(value) =>
          handleVariationChange(
            index,
            value,
            formData?.product?.product_line_items[index]?.variation
              ?.variation === "None" ||
              formData?.product?.product_line_items[index]?.changed
          )
        }
        labelStyle={{ fontWeight: "var(--lightFont)" }}
        msgStyle={{ color: "red" }}
        disabled={
          formData?.product?.product_line_items[index]?.variation?.variation ===
            "None" || formData?.product?.product_line_items[index]?.changed
            ? false
            : true
        }
      />
      <div className="labelStyle" style={{ marginTop: "20px" }}>
        MRP <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <CoreInputBox
        placeholder="₹ 0.00"
        type="number"
        value={formData?.product?.product_line_items[index]?.mrp || ""}
        setValue={(data) => {
          const numericValue = data
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/^(\d*\.\d{0,2}).*$/, "$1");
          handleInputChange(
            "product.product_line_items.mrp",
            numericValue,
            index
          );
        }}
        inputStyle={inputStyles}
        validateNumbers={
          formData?.product?.product_line_items[index]?.mrp == "int"
        }
        validatedecimal={
          formData?.product?.product_line_items[index]?.mrp == "float"
        }
        msgStyle={{ color: "red" }}
        showMSG={addNewMedicineErrorMsg[`mrp_${index}`]}
      />
      <div
        className="checkboxContainer mt-4"
        style={{ marginBottom: "8px", marginLeft: "2px" }}
      >
        <CoreCheckBox
          checked={formData?.product?.product_line_items[index]?.oos}
          title={"Out of Stock"}
          onChange={() => handleCheckboxChange("oos", index)}
          className="manualCheckox"
        />
      </div>
    </div>
  );
};

export default EditMedicine;
