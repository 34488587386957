import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { location, noteSave } from "res/images";
import Select2DropDown from "../common/Select2DropDown";
import SearchBox from "../common/SearchBox";
import CoreInputBox from "../CoreInputBox";
import CoreTextArea from "../CoreTextArea";
import { Modal } from "rsuite";
import PatientPrescription from "./PatientPrescription";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetPatientPrescriptionItemsMutation,
  useGetPrescriptionListQuery,
  useAddGenericNotesMutation,
} from "redux/dashboardApis";
import {
  setManualOrderItems,
  setManualOrderSummary,
  setLatestPrescription,
  setReduxNotesData,
  setFromViewOrderToOrderDetailSummary,
  setOrderAlreadyExist,
} from "redux/Slices";
import { resetWarned } from "react-bootstrap-typeahead/types/utils/warn";
import { profileImage } from "../Helper";
import useApiManager from "networking/ApiManager";
import { useParams } from "react-router-dom";
import { Loader } from "whealth-core-web/components";
import { Dropdown } from "rsuite";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const defaultVendorData = {
  contact_person: "Pushkar Anand",
  country_code: "+91",
  display_name: "1MG",
  id: 2,
  phone: "8150006259",
  vendor_logo:
    "https://staging-elevate-private.s3.amazonaws.com/private/assets/1mg.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARTVWU72VSUFRWCGF%2F20250102%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20250102T072359Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4cbb415f650b33e1f8fc9636edaf20f57f0316399dfb950489382c56450d2dbb",
  vendor_name: "1MG",
};

const OrderDetails = ({
  isNewMedicineAdded,
  setIsNewMedicineAdded,
  medicineErrors,
  publishedPrescription,
  orderSummaryShipping,
}) => {
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [medicines, setMedicines] = useState([]);
  const [medicinesList, setMedicinesList] = useState([]);
  const [orderDetail, setOrderDetail] = useState();
  const [manualOrderDetails, setManualOrderDetails] = useState();
  const [notes, setNotes] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [venderOptions, setVendorOptions] = useState([]);
  const [patientApiDetails, setPatientApiDetails] = useState({});
  const [getPrescriptionItems, prescriptionItemsData] =
    useGetPatientPrescriptionItemsMutation();
  const manualOrderItems = useSelector(
    (state) => state?.sliceReducer?.manualOrderItems
  );
  const manualOrderSummary = useSelector(
    (state) => state?.sliceReducer?.manualOrderSummary
  );
  const [orderSummary, setOrderSummary] = useState({
    orderTotal: 0,
    discount: 0,
    discount_percentage: 0,
    shipping: 0,
    total: 0,
  });
  const [selectedVendor, setSelectedVendor] = useState({});

  const [vendorValueArray, setVendorValueArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const fromViewOrderToOrderDetailSummary = useSelector(
    (state) => state?.sliceReducer?.fromViewOrderToOrderDetailSummary
  );
  const patientDetails = useSelector(
    (state) => state?.sliceReducer?.patientManualDetails
  );
  const patientAddress = useSelector(
    (state) => state?.sliceReducer?.patientAddress
  );
  const userData = useSelector((state) => state?.sliceReducer?.userData);
  const manualOrderId = useSelector(
    (state) => state?.sliceReducer?.manualOrderId
  );
  const reduxNotesData = useSelector(
    (state) => state?.sliceReducer?.reduxNotesData
  );
  const fromViewOrderToOrderDetail = useSelector(
    (state) => state?.sliceReducer?.fromViewOrderToOrderDetail
  );
  const draftOrderDetails = useSelector(
    (state) => state?.sliceReducer?.draftOrderDetails
  );
  const { orderId, patientId, orderStatus, idStr } = useParams();
  const [notesArr, setNotesArr] = useState([]);
  const [genericNotes, genericNotesResponse] = useAddGenericNotesMutation();
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [draftVariants, setDraftVariants] = useState({});
  const [loading, setLoading] = useState(
    fromViewOrderToOrderDetail ? true : false
  );
  const [clickedAddNewMedicine, setClickedAddNewMedicine] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiOrderId, setApiOrderId] = useState("");

  useEffect(() => {
    if (patientDetails?.id) {
      //getPatientPrescriptionItems(patientDetails?.id);
      fromViewOrderToOrderDetail
        ? fetchDraftVariants()
        : getPatientPrescriptionItems(patientDetails?.id);
      getPatientDetails(patientDetails?.id);
      getSelectedOrderDetail(patientId, orderId);
    } else if (patientId) {
      //getPatientPrescriptionItems(patientId);
      fromViewOrderToOrderDetail
        ? fetchDraftVariants()
        : getPatientPrescriptionItems(patientId);
      getPatientDetails(patientId);
      getSelectedOrderDetail(patientId, orderId);
      getManualOrderDetail(orderId);
    } else {
      const ordrId = localStorage.getItem("ordrId");
      const pAdd = localStorage.getItem("patientId");
      const pId = pAdd?.id;
      if (fromViewOrderToOrderDetail && ordrId) {
        fetchDraftVariants(ordrId);
        getPatientDetails(pId);
        getSelectedOrderDetail(pId, ordrId);
      }
    }
    dispatch(setManualOrderItems(medicines));
    calculateOrderSummary(medicines);
  }, []);

  useEffect(() => {
    if (orderId) {
      setIsNotesLoading(true);
      getManualOrderDetail(orderId);
      getAllNotes(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (manualOrderId || idStr) {
      fetchDraftVariants();
    }
  }, [manualOrderId, idStr]);

  // useEffect(() => {
  //   if (patientDetails?.id) {
  //     fromViewOrderToOrderDetail
  //       ? fetchDraftVariants()
  //       : getPatientPrescriptionItems(patientDetails?.id);
  //     getPatientDetails(patientDetails?.id);
  //   }
  // }, [patientDetails.id]);

  const getManualOrderDetail = (oid) => {
    ApiClient.getManualOrder(oid)
      .then((res) => {
        setLoading(false);
        setManualOrderDetails(res?.data);
        setOrderSummary({
          orderTotal: res?.data.before_discount_amount,
          discount: res?.data?.discounted_amount,
          discount_percentage: (
            (parseFloat(res?.data?.discounted_amount) /
              parseFloat(res?.data?.before_discount_amount)) *
            100
          ).toFixed(2),
          shipping: res?.data?.shipping_amount,
          total: res?.data?.total_amount,
        });
      })
      .catch((err) => {
        console.log("getManualOrder err: ", err);
      });
  };

  // const getVendorDetail = () => {
  //   ApiClient.getVendorDetail()
  //     .then((res) => {
  //       setIsLoading(false);
  //       setVendorOptions(res.data);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  const fetchDraftVariants = async (oID) => {
    const orderId = oID || idStr || orderDetail?.order_id || manualOrderId;
    let params = {
      order_id:
        typeof orderId === "object" && orderId !== null
          ? orderId.order_id
          : orderId,
    };

    try {
      const response = await ApiClient.getDraftVariants(params);
      setDraftVariants(response.data);
      setMedicines([]);
      dispatch(setManualOrderItems([]));

      const result = Object.values(response.data)
        .map((items) => {
          // Select the item with "selected" key set to true
          const selectedItem = items.find((item) => item.selected);
          if (!selectedItem) return null;
          const grouped = items.reduce((acc, item) => {
            const { variation, ...rest } = item;

            // const productPrice = item.product_price[0] || {}; // Handle empty product_price
            // const price = productPrice.price || 0; // Default to 0 if no price
            // const mrp = productPrice.mrp || 0; // Default to 0 if no MRP
            const selectedVendorInfo = selectedItem.product_price?.find(
              (item) => item?.vendor?.id === selectedItem?.vendor?.id
            );
            const mrp = selectedVendorInfo?.mrp;
            const price = selectedVendorInfo?.price;

            // Only include the selected item in the accumulator
            if (!acc[rest.product.id]) {
              acc[rest.product.id] = {
                ...selectedItem,
                product_name: rest.product.product_name,
                quantity: selectedItem.quantity_in_cart || 1, //item.quantity_in_cart || 1,
                selectedVariation: selectedItem.id, //selectedVariationId,
                discount: selectedItem.discount || 15, //rest.discount || 15,
                medicineId: selectedItem.id, //rest.id,
                price: price, //(parseFloat(selectedItem.mrp) * (1 - selectedItem.discount / 100))?.toFixed(2), //(parseFloat(rest.mrp) * (1 - rest.discount/100))?.toFixed(2), //(parseFloat(rest.mrp) * 0.85)?.toFixed(2),
                mrp: mrp, //parseFloat(selectedItem.mrp)?.toFixed(2), //parseFloat(rest.mrp)?.toFixed(2),
                variations: [],
                product_price: selectedItem?.product_price,
                vendor: selectedVendorInfo?.vendor,
              };
            }

            const variationWithLabelKey = {
              ...variation,
              medicineId: item.id,
              labelKey: `${variation.name} of ${variation.variation}`,
              mrp: mrp, // Set MRP from product_price
              price: price, // Set price from product_price
            };

            acc[rest.product.id].variations.push(variationWithLabelKey);
            return acc;
          }, {});

          return Object.values(grouped);
        })
        .flat();

      setMedicinesList(result);
      setMedicines(result);
      const filteredResult = result.map((item) => {
        const { quantity_in_cart, selected, variations, ...rest } = item; // Destructure to exclude keys
        const filteredVariations = variations.map((variation) => {
          const { quantity_in_cart, selected, ...restVariation } = variation; // Exclude keys from variations
          return restVariation; // Return the filtered variation
        });
        return {
          ...rest, // Return the rest of the item properties
          variations: filteredVariations, // Include the filtered variations
        };
      });
      dispatch(setManualOrderItems(filteredResult));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching draft variants:", error);
    }
  };

  const getSelectedOrderDetail = (id, oid) => {
    ApiClient.getSelectedOrderDetail(id, oid)
      .then((res) => {
        setOrderDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNotes = (id) => {
    ApiClient.getOrderNotes(id)
      .then((res) => {
        setNotesArr(res.data.results);
        setIsNotesLoading(false);
      })
      .catch((err) => {
        console.log("getNotes error: ", err);
        setIsNotesLoading(false);
      });
  };

  const getPatientDetails = (id) => {
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setPatientApiDetails(res.data);
      })
      .catch((err) => {
        console.log("getNotes error: ", err);
      });
  };

  const userImage = (item) => {
    const imgSource = profileImage(item.gender, item["profile_url"]);
    return (
      <img src={imgSource} style={{ height: 24 }} className="rounded-circle" />
    );
  };

  function getOrderId(prescriptionItems) {
    if (prescriptionItems?.data) {
      const keys = Object.keys(prescriptionItems.data);
      if (keys.length > 0) {
        const firstKey = keys[0];
        const items = prescriptionItems.data[firstKey];
        if (items && items.length > 0) {
          setApiOrderId(items[0].order_id || null);
          return items[0].order_id || null; // Return order_id or null if it doesn't exist
        }
      }
    }
    return null; // Return null if no order_id is found
  }

  const findVendorByName = (productPriceArray, vendorName) => {
    console.log("product price arr", productPriceArray);
    const vendorEntry = productPriceArray.find(
      (item) =>
        item.vendor.vendor_name.toLowerCase() === vendorName.toLowerCase()
    );
    return vendorEntry ? vendorEntry.vendor : null;
  };

  const findVendor = (productPriceArray, vendorName) => {
    const vendorEntry = productPriceArray.find(
      (item) =>
        item.vendor.vendor_name.toLowerCase() === vendorName.toLowerCase()
    );
    return vendorEntry ? vendorEntry : null;
  };

  const getPatientPrescriptionItems = async (pId) => {
    if (
      manualOrderItems?.length === 0 ||
      Object.keys(manualOrderItems).length === 0
    ) {
      const prescriptionItems = await getPrescriptionItems({
        id: pId,
      }).unwrap();
      const ordrId = getOrderId(prescriptionItems);

      if (ordrId) {
        //fetchDraftVariants(ordrId);
        // toast.success(`Order for this prescription already exist, check Order ID: ${ordrId}`, {
        //   position: 'top-right',
        //   //autoClose: 5000,
        // })
        dispatch(setOrderAlreadyExist(ordrId));
        navigate("/orders");
      } else {
        setMedicines([]);
        dispatch(setManualOrderItems([]));
        const result = Object.values(prescriptionItems?.data)
          .map((items) => {
            const grouped = items.reduce((acc, item) => {
              const { variation, vendor, ...rest } = item;
              // Extract the first product_price for price and mrp
              const productPrice = item.product_price[0]; // Assuming you want the first price entry
              const vendorObj = findVendor(item.product_price, "1MG");
              const vendorObjElevate = findVendor(
                item.product_price,
                "ElevateNow"
              );
              const variationWithLabelKey = {
                ...variation,
                medicineId: item.id,
                labelKey: `${variation.name} of ${variation.variation}`,
                mrp: productPrice?.mrp, // Set MRP from product_price
                price: productPrice?.price, // Set price from product_price
              };

              if (!acc[rest.product.id]) {
                acc[rest.product.id] = {
                  ...rest,
                  product_name: rest.product.product_name,
                  quantity: 1,
                  selectedVariation: variation.id, // Set to the current variation ID
                  discount: 15,
                  medicineId: rest.id,
                  price: productPrice?.price, // Set price from product_price
                  mrp: productPrice?.mrp, // Set MRP from product_price
                  variations: [],
                  product_price: item.product_price, // Store product_price array
                  vendor: vendorObj
                    ? vendorObj?.vendor
                    : vendorObjElevate?.vendor,
                };
              }
              acc[rest.product.id].variations.push(variationWithLabelKey);
              return acc;
            }, {});
            return Object.values(grouped);
          })
          .flat();
        setMedicinesList(result);
        setMedicines(result);
        dispatch(setManualOrderItems(result));
      }
    } else {
      const prescriptionItems = await getPrescriptionItems({
        id: patientId || patientDetails?.id,
      }).unwrap();
      const ordrId = getOrderId(prescriptionItems);
      localStorage.setItem("ordrId", ordrId);
      if (ordrId) {
        //fetchDraftVariants(ordrId);
        // toast.success(`Order for this prescription already exist, check Order ID: ${ordrId}`, {
        //   position: 'top-right',
        //   //autoClose: 5000,
        // })
        dispatch(setOrderAlreadyExist(ordrId));
        navigate("/orders");
      } else {
        setMedicines([]);
        dispatch(setManualOrderItems([]));
        const result = Object.values(prescriptionItems?.data)
          .map((items) => {
            const grouped = items.reduce((acc, item) => {
              const { variation, ...rest } = item;
              const productPrice = item.product_price[0];
              const vendorObj = findVendor(item.product_price, "1MG");
              const vendorObjElevate = findVendor(
                item.product_price,
                "ElevateNow"
              );
              const variationWithLabelKey = {
                ...variation,
                medicineId: item.id,
                labelKey: `${variation.name} of ${variation.variation}`,
                mrp: item.mrp,
                price: item.mrp * 0.85,
                sku: item.sku,
              };
              if (!acc[rest.product.id]) {
                acc[rest.product.id] = {
                  ...rest,
                  product_name: rest.product.product_name,
                  quantity: 1,
                  selectedVariation: variation.id, // Set to the current variation ID
                  discount: 15,
                  medicineId: rest.id,
                  price: productPrice?.price, // Set price from product_price
                  mrp: productPrice?.mrp, // Set MRP from product_price
                  variations: [],
                  product_price: item.product_price, // Store product_price array
                  vendor: vendorObj
                    ? vendorObj?.vendor
                    : vendorObjElevate?.vendor,
                };
              }
              acc[rest.product.id].variations.push(variationWithLabelKey);
              return acc;
            }, {});
            return Object.values(grouped);
          })
          .flat();
        setMedicinesList(result);
        setMedicines(result);
        dispatch(setManualOrderItems(result));
      }
    }
  };

  useEffect(() => {
    calculateOrderSummary(medicines);
  }, [medicines, manualOrderItems]);

  const prescriptionListQuery = useGetPrescriptionListQuery({
    id: patientId || patientDetails?.id,
  });
  const prescriptionList = prescriptionListQuery?.data?.data || [];

  useEffect(() => {
    if (prescriptionList.length > 0) {
      const prescription = prescriptionList.find(
        (item) => item?.status === "published"
      );
      if (prescription) {
        dispatch(setLatestPrescription(prescription));
      }
    }
  }, [prescriptionList, dispatch]);

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };
  const handleQuantityChange = (id, amount) => {
    setIsNewMedicineAdded(true);

    const updatedMedicines = medicines
      .map((medicine) => {
        if (medicine.id === id) {
          if (fromViewOrderToOrderDetail && !setClickedAddNewMedicine) {
            const selectedVariation = medicine.variations.find(
              (variation) => variation.selected
            );
            if (selectedVariation) {
              // Calculate new quantity
              const newQuantity =
                medicine.quantity_in_cart ||
                selectedVariation.quantity_in_cart + amount;
              // Validate quantity limits
              let validatedQuantity;
              if (newQuantity > 60) {
                setErrorMessage("Quantity cannot be more than 60");
                validatedQuantity = 60;
              } else if (newQuantity < 0) {
                validatedQuantity = 0;
              } else {
                setErrorMessage("");
                validatedQuantity = newQuantity;
              }

              // Update the variations array with new quantity
              const updatedVariations = medicine.variations.map((variation) =>
                variation.selected
                  ? {
                      ...variation,
                      quantity_in_cart: validatedQuantity,
                    }
                  : variation
              );

              const price =
                selectedVariation.price ||
                medicine.product_price[0]?.price ||
                0; // Fallback to first price
              const mrp =
                selectedVariation.mrp || medicine.product_price[0]?.mrp || 0; // Fallback to first MRP

              // Return updated medicine object with both quantity and quantity_in_cart
              return {
                ...medicine,
                variations: updatedVariations,
                quantity: validatedQuantity, // Update quantity
                quantity_in_cart: validatedQuantity, // Update quantity_in_cart
                mrp: mrp,
                price: price,
              };
            }
          } else {
            // Handle non-fromViewOrderToOrderDetail case
            const newQuantity = medicine.quantity + amount;

            let validatedQuantity;
            if (newQuantity > 60) {
              setErrorMessage("Quantity cannot be more than 60");
              validatedQuantity = 60;
            } else if (newQuantity < 0) {
              validatedQuantity = 0;
            } else {
              setErrorMessage("");
              validatedQuantity = newQuantity;
            }

            const price = medicine.product_price[0]?.price || 0; // Fallback to first price
            const mrp = medicine.product_price[0]?.mrp || 0; // Fallback to first MRP

            return {
              ...medicine,
              quantity: validatedQuantity,
              quantity_in_cart: validatedQuantity, // Keep both quantities in sync
              mrp: mrp,
              price: price,
            };
          }
        }
        return medicine; // Return unchanged medicine for non-matching IDs
      })
      .filter((medicine) => medicine.quantity > 0);

    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  const calculateOrderSummary = (medicines) => {
    let mrpTotal = 0;
    let discountTotal = 0;

    if (medicines.length > 0) {
      medicines.forEach((medicine) => {
        // Get MRP from the first product_price entry
        const medicineMRP =
          parseFloat(
            Array.isArray(medicine?.product_price) &&
              medicine.product_price.length > 0
              ? medicine.product_price[0]?.mrp
              : 0
          ) || 0;

        // Get discount and quantity with fallbacks
        const medicineDiscount = parseFloat(medicine?.discount) || 0;
        const medicineQuantity = parseInt(medicine?.quantity, 10) || 0;

        // Calculate discount total
        discountTotal +=
          ((medicineMRP * medicineDiscount) / 100) * medicineQuantity;

        // Calculate MRP total
        mrpTotal += medicineMRP * medicineQuantity;
      });
    }

    const total = mrpTotal - discountTotal;

    setOrderSummary((prevOrderSummary) => {
      let newManualOrderSummary = {
        orderTotal: mrpTotal,
        discount: discountTotal,
        discount_percentage: medicines[0]?.discount || 0, // Assuming discount percentage is the same for all medicines
        shipping: orderSummaryShipping.shipping || prevOrderSummary.shipping,
        total:
          Number(total) + Number(orderSummaryShipping.shipping) ||
          Number(prevOrderSummary.shipping),
      };
      dispatch(setManualOrderSummary(newManualOrderSummary));
      dispatch(setFromViewOrderToOrderDetailSummary(newManualOrderSummary));
      return newManualOrderSummary;
    });
  };

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    if (!notes.trim()) {
      return;
    }
    const data = {
      content: notes,
      model_name: "order",
      model_id: manualOrderId | orderId,
    };
    try {
      const response = await genericNotes({ data });
      setNotesArr([...notesArr, response.data.data]);

      if (response.error) {
        throw new Error(response.error);
      }
      setNotes("");
    } catch (e) {
      console.error("Payment recording failed:", e);
    }
  };

  const handleShippingChange = (value) => {
    setOrderSummary((prevOrderSummary) => {
      let newOrderSummary = {
        orderTotal: prevOrderSummary.orderTotal,
        discount: prevOrderSummary.discount,
        shipping: parseFloat(value) > 0 ? parseFloat(value) : 0,
        total:
          parseFloat(value) > 0
            ? prevOrderSummary.orderTotal -
              prevOrderSummary.discount +
              parseFloat(value)
            : prevOrderSummary.orderTotal - prevOrderSummary.discount,
      };
      dispatch(setManualOrderSummary(newOrderSummary));
      return newOrderSummary;
    });
  };

  const handleAddMedicine = (selectedMedicine) => {
    setIsNewMedicineAdded(true);
    setClickedAddNewMedicine(true);
    const newId = medicines.length > 0 ? medicines.length + 1 : 1;
    const oneMGVendor = selectedMedicine.product_price?.find(
      (item) => item.vendor.vendor_name === "1MG"
    );

    const newMedicine = {
      id: newId,
      product_name: "",
      sku: "",
      quantity: 1,
      product: {
        id: null,
        product_name: "",
        medicinemedicine: {
          id: null,
          name: "",
          formulation: "",
        },
        category: "",
        specifications: null,
        image_url: null,
      },
      variations: [],
      mrp: oneMGVendor ? oneMGVendor.mrp : 0,
      price: oneMGVendor ? oneMGVendor.price : 0,
      discount: 15,
      manufacturer: {
        id: null,
        name: "",
      },
      oos: false,
      quantity_prescribed: null,
      quantity_available: 1,
      active: true,
      selectedVariation: null,
      vendor: {
        id: null,
        vendor_name: "",
        vendor_logo: "",
        contact_person: "",
        phone: "",
        country_code: "",
        display_name: "",
      },
    };
    //setMedicines([...medicines, newMedicine]);
    setMedicines((prevMedicines) => [...prevMedicines, newMedicine]);
    dispatch(setManualOrderItems([...medicines, newMedicine]));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  console.log("mdeicines: ", medicines);

  const handleSelectNewMedicine = (newMedicine, id) => {
    const oneMGVendor = newMedicine.product_price?.find(
      (price) => price.vendor.vendor_name === "1MG"
    );
    if (newMedicine) {
      setMedicines((prevMedicines) => {
        let updatedMedicines = [...prevMedicines];
        const { variation, ...rest } = newMedicine;
        //const labelKey = newMedicine?.product?.product_name;
        console.log("handleSelectNewMedicine variation: ", variation);
        const labelKey = `${variation?.name} of ${variation?.variation}`;
        const existingMedicineIndex = updatedMedicines.findIndex((medicine) =>
          medicine.variations.some((v) => v.medicineId === newMedicine.id)
        );

        if (existingMedicineIndex !== -1) {
          // If a match is found, update the quantity of the matched medicine
          const updatedMedicine = {
            ...updatedMedicines[existingMedicineIndex],
            quantity: updatedMedicines[existingMedicineIndex].quantity + 1,
          };
          updatedMedicines[existingMedicineIndex] = updatedMedicine;
          updatedMedicines.pop();
        } else {
          let newMedicineIndex = updatedMedicines.findIndex(
            (medicine) => medicine.id === id
          );
          const updatedMedicine = {
            ...rest,
            variations: [
              {
                ...variation,
                medicineId: newMedicine.id,
                mrp: newMedicine.mrp,
                price: newMedicine.mrp * 0.85,
                sku: newMedicine.sku,
                labelKey,
              },
            ],
            quantity: 1,
            mrp: oneMGVendor
              ? parseFloat(oneMGVendor.mrp).toFixed(2)
              : parseFloat(newMedicine.mrp)?.toFixed(2),
            price: oneMGVendor
              ? parseFloat(oneMGVendor?.price)?.toFixed(2)
              : (parseFloat(newMedicine.mrp) * 0.85)?.toFixed(2),
            discount: 15,
            medicineId: newMedicine.id,
            sku: "",
            selectedVariation: null,
            vendor: newMedicine?.product_price[0]?.vendor,
          };
          updatedMedicines[newMedicineIndex] = updatedMedicine;
        }
        dispatch(setManualOrderItems(updatedMedicines));
        calculateOrderSummary(updatedMedicines);
        return updatedMedicines;
      });
    }
  };

  // const handleSetDiscount = (medicineId, value) => {
  //   const discountValue = value > 0 ? (value <= 100 ? value : 100) : 0;
  //   const updatedMedicines = medicines.map((medicine) =>
  //     medicine.id === medicineId
  //       ? {
  //           ...medicine,
  //           discount: discountValue,
  //           price: (
  //             medicine.mrp -
  //             (medicine.mrp * discountValue) / 100
  //           )?.toFixed(2),
  //         }
  //       : medicine
  //   );
  //   setMedicines(updatedMedicines);
  //   dispatch(setManualOrderItems(updatedMedicines));
  // };

  const handleSetDiscount = (medicineId, value) => {
    console.log("handleSetDiscount value: ", value);
    const discountValue = value > 0 ? (value <= 100 ? value : 100) : 0; // Ensure discount is between 0 and 100
    const updatedMedicines = medicines.map((medicine) => {
      if (medicine.id === medicineId) {
        // Check if the vendor is not "1mg"
        if (medicine.vendor.vendor_name !== "1mg") {
          const price = medicine.product_price[0]?.mrp || 0; // Get the original price
          const discountedPrice = price - (price * discountValue) / 100; // Calculate discounted price
          const amount = discountedPrice * medicine.quantity; // Calculate amount based on quantity

          return {
            ...medicine,
            discount: discountValue, // Update discount
            price: discountedPrice, // Update price
            amount: amount, // Update amount
          };
        } else {
          // If vendor is "1mg", do not allow discount
          return {
            ...medicine,
            discount: 15, // Reset discount
            price: medicine.product_price[0]?.price || 0, // Reset price to original
            amount: (medicine.product_price[0]?.price || 0) * medicine.quantity, // Recalculate amount
          };
        }
      }
      return medicine; // Return unchanged medicine for non-matching IDs
    });

    setMedicines(updatedMedicines); // Update state with new medicines
    dispatch(setManualOrderItems(updatedMedicines)); // Update Redux state if necessary
  };

  const handleSelectVariation = (medicineId, id) => {
    const updatedMedicines = medicines.map((medicine) => {
      if (medicine.id === medicineId) {
        const selectedVariation = medicine.variations.find(
          (variation) => variation.medicineId === id
        );
        if (selectedVariation) {
          // return {
          //   ...medicine,
          //   id: id,
          //   medicineId: id,
          //   mrp: selectedVariation.mrp,
          //   price: selectedVariation.mrp * 0.85,
          //   discount: 15, //medicine.discount || 15,
          //   sku: selectedVariation.sku,
          //   selectedVariation: id,
          // };
          const updatedMedicine = {
            ...medicine,
            id: id,
            medicineId: id,
            mrp: selectedVariation.mrp,
            price: selectedVariation.mrp * 0.85,
            discount: 15, // or whatever logic you have for discount
            sku: selectedVariation.sku,
            selectedVariation: id,
          };

          // Logic for when fromViewOrderToOrderDetail is true
          if (fromViewOrderToOrderDetail) {
            // Example: Set a default variation if none is selected
            if (!updatedMedicine.selectedVariation) {
              updatedMedicine.selectedVariation = getDefaultVariation(
                medicine.variations
              );
            }
          }

          return updatedMedicine;
        }
      } else {
        return medicine;
      }
    });
    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  const handleClearVariation = (medicineId) => {
    const updatedMedicines = medicines.map((medicine) => {
      if (medicine.id === medicineId) {
        return {
          ...medicine,
          selectedVariation: null,
          sku: "",
        };
      } else {
        return medicine;
      }
    });
    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  const getDefaultVariation = (variations) => {
    const activeVariation = variations.find((variation) => variation.selected);
    return activeVariation ? activeVariation.medicineId : null;
  };

  const getQuantityInCart = (variations) => {
    const activeVariation = variations.find((variation) => variation.selected);
    return activeVariation ? activeVariation.quantity_in_cart : 1; // Return quantity_in_cart or 0 if not found
  };

  const getVendorsFromMedicine = (medicine) => {
    return medicine?.product_price?.map((el) => el?.vendor);
  };

  function getMedicineIdFromSelectedVariation(obj) {
    if (!obj || !obj.variations || !Array.isArray(obj.variations)) {
      return null; // Return null if the object or variations array is invalid
    }

    const selectedVariation = obj.variations.find(
      (variation) => variation.id === obj.selectedVariation
    );
    return selectedVariation ? selectedVariation.medicineId : null; // Return medicineId if found, else null
  }

  return (
    <div className="order-details">
      <p
        className="status-text align-items-center capitalize active align-items-center d-flex"
        style={{
          position: "absolute",
          background: "#EBEFFC",
          borderRadius: "0.5rem",
          lineHeight: "1rem",
          color: "#243B86",
          padding: "3px 8px 3px 8px",
        }}
      >
        Draft
      </p>
      <div className="order-header">
        <div
          className="d-flex align-items-center"
          style={{
            background: "#F9FAFE",
            padding: "10px",
            borderRadius: "8px",
            width: "50%",
          }}
        >
          <div className="mr-2">
            Source: Manual
            <p>
              Order ID :{" "}
              {apiOrderId ||
                orderDetail?.order_id ||
                localStorage.getItem("ordrId") ||
                ""}
            </p>
          </div>
          <div className="vl">.</div>

          <div className="mx-2 patient-name d-flex gap-2">
            {userImage(patientApiDetails)}
            <div>
              {patientDetails.full_name ||
                orderDetail?.user?.full_name ||
                patientApiDetails?.full_name}
            </div>
          </div>
          <div className="mx-2 patient-address d-flex">
            <div className="mx-2">
              <img src={location} alt="location" />
            </div>
            <div>
              {orderDetail?.shipping_address ? (
                <div>{orderDetail?.shipping_address}</div>
              ) : (
                <>
                  <div>
                    {patientAddress?.address_line1},{" "}
                    {patientAddress?.address_line2}
                  </div>
                  <div>
                    {patientAddress?.city}, {patientAddress?.state},{" "}
                    {patientAddress?.pincode}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setEditMode(true);
          }}
          className="reusableBtnActive  reusableBtn "
          style={{
            background: "#fff",
            border: "1px solid #133696",
            color: "#133696",
          }}
        >
          View Prescription
        </div>
        <Modal
          backdrop="static"
          size="md"
          open={isEditMode}
          onClose={() => {
            setEditMode(false);
          }}
        >
          <Modal.Header>
            <div className="settingFormOutline p-0">
              <div className="formTitle mb-0">Prescription</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PatientPrescription patientId={patientDetails?.id} />
          </Modal.Body>
        </Modal>
      </div>
      {errorMessage && <div className="inputMsg">{errorMessage}</div>}
      <div className="mt-4 mb-4 table-responsive1">
        <table
          className="table order-details-table"
          style={{ marginBottom: "0" }}
        >
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Medicine</th>
              {/* <th>SKU</th> */}
              <th>Variation</th>
              <th>Quantity</th>
              <th>Vendor</th>
              <th>Discount</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {medicines.length > 0 &&
              medicines.map((medicine, index) => {
                // Identify the default vendor from the API response
                let defaultVendor = medicine.vendor; //null;
                let defaultVendorPrice = null;
                const isOneMg = medicine?.vendor?.vendor_name === "1mg";
                // Check for vendor "1MG"
                const oneMGVendor = medicine.product_price?.find(
                  (item) => item.vendor.vendor_name === "1MG"
                );
                if (medicine.vendor) {
                  defaultVendor = medicine.vendor; // Outer "vendor" key as default
                  defaultVendorPrice = medicine.product_price?.find(
                    (price) => price.vendor?.id === defaultVendor.id
                  );
                }

                // Auto-populate vendor and pricing details
                const vendorName = defaultVendor?.vendor_name || "Select";
                const originalPrice =
                  defaultVendorPrice?.price || medicine.price;
                const discountValue =
                  parseFloat(medicine.discount).toFixed(2) || 15;
                const price = isOneMg ? originalPrice : medicine.price;
                const mrp = defaultVendorPrice?.mrp || medicine.mrp;
                const vendors = getVendorsFromMedicine(medicine);

                return (
                  <tr key={medicine.id}>
                    <td>{index + 1}</td>
                    <td>
                      {medicine?.product?.product_name ? (
                        <div>{medicine.product.product_name}</div>
                      ) : (
                        <SearchBox
                          placeHolder="Search medicine"
                          searchMethod="PRODUCTLINEITEMSEARCH"
                          label="product.product_name"
                          searchId="Patients"
                          defaultInputValue=""
                          nestedLabelKey={true}
                          handleItemClick={(item) => {
                            handleSelectNewMedicine(item, medicine.id);
                          }}
                          imagePath="profile_url"
                        />
                      )}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <Select2DropDown
                        valueKey="medicineId"
                        labelKey="labelKey"
                        //value={medicine.selectedVariation || ""}
                        value={
                          fromViewOrderToOrderDetail
                            ? medicine.selectedVariation ||
                              getMedicineIdFromSelectedVariation(medicine) ||
                              medicine.variations?.[0]?.medicineId ||
                              ""
                            : medicine.variations?.[0]?.medicineId
                        }
                        // value={
                        //   oneMGVendor
                        //     ? medicine.variations?.[0]?.medicineId // Ensure the first variation is valid
                        //     : getMedicineIdFromSelectedVariation(medicine) || medicine.variations?.[0]?.medicineId // Fallback to selected variation or empty
                        // }
                        onSelect={(id) =>
                          handleSelectVariation(medicine.id, id)
                        }
                        onClean={() => handleClearVariation(medicine.id)}
                        data={medicine.variations}
                        placeholder="Select"
                      />
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button
                        className="minus-btn"
                        onClick={() => handleQuantityChange(medicine.id, -1)}
                      >
                        -
                      </button>
                      <span className="medicine-quant">
                        {/* {fromViewOrderToOrderDetail && !isNewMedicineAdded
                          ? getQuantityInCart(medicine.variations).toString().padStart(2, '0')
                          : medicine.quantity.toString().padStart(2, "0")} */}
                        {medicine.quantity.toString().padStart(2, "0")}
                      </span>
                      <button
                        className="add-btn"
                        onClick={() => handleQuantityChange(medicine.id, 1)}
                      >
                        +
                      </button>
                    </td>
                    <td>
                      {/* <Dropdown
                        className="capitalize pointer"
                        //title={vendorValueArray[index] || vendorName}
                        title={
                          fromViewOrderToOrderDetail &&
                          medicine.vendor?.vendor_name ? (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {medicine.vendor.vendor_logo && (
                                <img
                                  src={medicine.vendor.vendor_logo}
                                  height="15px"
                                  width="15px"
                                  style={{ objectFit: "contain" }}
                                  alt={medicine.vendor.vendor_name}
                                />
                              )}
                              {medicine.vendor.vendor_name}
                            </span>
                          ) : vendorValueArray[index] ? (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {medicine.vendor?.vendor_logo && (
                                <img
                                  src={medicine.vendor.vendor_logo}
                                  height="15px"
                                  width="15px"
                                  style={{ objectFit: "contain" }}
                                  alt={medicine.vendor.vendor_name}
                                />
                              )}
                              {vendorValueArray[index]}
                            </span>
                          ) : (
                            "Select"
                          )
                        }
                      >
                        {medicine.product_price?.map((item, index3) => (
                          <Dropdown.Item
                            key={index3}
                            onClick={() => {
                              setVendorValueArray((prevVendorValueArray) => {
                                const updatedArray = [...prevVendorValueArray];
                                updatedArray[index] = item.vendor.vendor_name;

                                const updatedMedicines = medicines.map(
                                  (med, idx) => {
                                    if (idx === index) {
                                      return {
                                        ...med,
                                        price: item.price,
                                        mrp: item.mrp,
                                        vendor: item.vendor,
                                      };
                                    }
                                    return med;
                                  }
                                );

                                setMedicines(updatedMedicines);
                                dispatch(setManualOrderItems(updatedMedicines));

                                return updatedArray;
                              });
                            }}
                          >
                            {item.vendor.vendor_logo && (
                              <img
                                src={item.vendor.vendor_logo}
                                height="15px"
                                width="15px"
                                style={{
                                  margin: "-2px 4px 0 3px",
                                  objectFit: "contain",
                                }}
                                alt={item.vendor.vendor_name}
                              />
                            )}
                            {item.vendor.vendor_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown> */}
                      <Dropdown
                        className="capitalize pointer"
                        style={{ width: "143px" }}
                        title={
                          defaultVendor ? (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {defaultVendor?.vendor_logo && (
                                <img
                                  src={defaultVendor?.vendor_logo}
                                  height="15px"
                                  width="15px"
                                  style={{ objectFit: "contain" }}
                                  alt={defaultVendor?.vendor_name}
                                />
                              )}
                              {defaultVendor?.vendor_name}
                            </span>
                          ) : selectedVendor[index] ? (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {selectedVendor[index]?.vendor_logo && (
                                <img
                                  src={selectedVendor[index]?.vendor_logo}
                                  height="15px"
                                  width="15px"
                                  style={{ objectFit: "contain" }}
                                  alt={selectedVendor[index]?.vendor_name}
                                />
                              )}
                              {selectedVendor[index]?.vendor_name}
                            </span>
                          ) : (
                            <span>Select</span>
                          )
                        }
                      >
                        {vendors?.map((item, index3) => {
                          return (
                            <DropDownItem
                              item={item}
                              index={index}
                              setSelectedVendor={setSelectedVendor}
                              setVendorValueArray={setVendorValueArray}
                              medicines={medicines} // Pass medicines to update state
                              setMedicines={setMedicines}
                              dispatch={dispatch}
                            />
                          );
                        })}
                      </Dropdown>
                    </td>
                    {/* <td style={{ whiteSpace: "nowrap", pointerEvents: (medicine?.vendor?.vendor_name === '1MG' || defaultVendor.vendor_name === '1MG') ? 'none' : 'auto' }}> */}
                    <td style={{ whiteSpace: "nowrap" }}>
                      <CoreInputBox
                        validateNumbers={true}
                        type="number"
                        placeholder="Discount %"
                        //value={isOneMg ? 15 : medicine.discount}
                        value={
                          fromViewOrderToOrderDetail
                            ? medicine.discount
                            : isOneMg
                            ? 15
                            : medicine.discount
                        }
                        setValue={(value) =>
                          handleSetDiscount(medicine.id, value)
                        }
                        showUnits={medicine.discount ? "%" : ""}
                        disabled={
                          medicine?.vendor?.vendor_name === "1MG" ||
                          defaultVendor?.vendor_name === "1MG"
                        }
                      />
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {parseInt(medicine.discount) > 0 ? (
                        <div>
                          <div>Rs. {Number(price).toFixed(2)}</div>
                          <div
                            style={{
                              fontSize: "12px",
                              textDecoration: "line-through",
                            }}
                          >
                            Rs. {Number(mrp).toFixed(2)}
                          </div>
                        </div>
                      ) : (
                        <div>Rs. {Number(mrp).toFixed(2)}</div>
                      )}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Rs.{" "}
                      {(
                        (parseInt(medicine.discount) > 0 ? price : mrp) *
                        medicine.quantity
                      ).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div>
          <button className="add-medicine" onClick={handleAddMedicine}>
            + Add Another Medicine
          </button>
        </div>
        <div className="inputMsg">
          {medicineErrors.length > 0 ? medicineErrors : ""}
        </div>
      </div>

      {loading ? (
        <Loader show={loading} />
      ) : (
        <div className="row justify-content-end">
          <div className="col-lg-3">
            <div className="order-summary p-3 bg-light rounded">
              <h6 className="fw-bold mb-3">Order Summary</h6>
              <div className="d-flex justify-content-between mb-2">
                <div>Order Total</div>
                <div>
                  Rs.{" "}
                  {parseFloat(orderSummary?.orderTotal).toFixed(2) ||
                    parseFloat(orderSummaryShipping?.orderTotal).toFixed(2)}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Discount</div>
                <div>
                  -Rs.{" "}
                  {orderSummary?.discount
                    ? parseFloat(orderSummary?.discount).toFixed(2)
                    : 0 || orderSummaryShipping?.discount
                    ? parseFloat(orderSummaryShipping?.discount).toFixed(2)
                    : 0}{" "}
                  {orderSummary?.discount_percentage
                    ? `(${orderSummary?.discount_percentage}%)`
                    : `(${manualOrderDetails?.discount_percentage}%)`}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Shipping</div>
                <div>Free Shipping</div>
                {/* {
                  (parseFloat(orderSummary?.total).toFixed(2) ||
                  parseFloat(orderSummaryShipping?.total).toFixed(2)) < 1000 ? (
                    <div style={{ width: '40%' }}>
                      <CoreInputBox
                        value={orderSummaryShipping?.shipping || orderSummary?.shipping || ''}
                        setValue={(value) => handleShippingChange(value)}
                      />
                    </div>
                  ) : <div>Free Shipping</div>
                } */}
              </div>
              <div className="hrLine mb-3" />
              <div className="d-flex justify-content-between fw-bold">
                <div>Total</div>
                <div>
                  Rs.{" "}
                  {parseFloat(orderSummary?.total).toFixed(2) ||
                    parseFloat(orderSummaryShipping?.total).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="hrLine mb-3" style={{ background: "#D1DCFF" }} />
      {orderStatus === "draft" && (
        <div className="row">
          <div className="col-lg-12">
            <div className="notes-section mt-3">
              <label className="fw-bold">Notes</label>
              <div className="textarea-container">
                <textarea
                  value={notes}
                  onChange={handleNoteChange}
                  className="form-control mb-3"
                />
                <button className="save-button" onClick={handleSaveNotes}>
                  <img src={noteSave} alt="Save Note" />
                </button>
              </div>

              {notesArr.length > 0 ? (
                notesArr
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className="note-container mt-2">
                      <div className="mr-2" style={{ marginRight: "10px" }}>
                        <img
                          src={profileImage(
                            patientDetails.gender,
                            userData?.profile_url
                          )}
                          style={{ height: 40 }}
                          alt="Profile"
                        />
                      </div>
                      <div className="note">
                        <div className="note-time">
                          {formatDate(note?.updated_at)}
                        </div>
                        <div className="note-content">{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : reduxNotesData.length > 0 ? (
                reduxNotesData
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className="note-container mt-2">
                      <div className="mr-2" style={{ marginRight: "10px" }}>
                        <img
                          src={profileImage(
                            patientDetails.gender,
                            userData?.profile_url
                          )}
                          style={{ height: 40 }}
                          alt="Profile"
                        />
                      </div>
                      <div className="note">
                        <div className="note-time">
                          {formatDate(note?.updated_at)}
                        </div>
                        <div className="note-content">{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="note-container">
                  <div className="mr-2" style={{ marginRight: "10px" }}>
                    <img
                      src={profileImage(
                        patientDetails.gender,
                        userData?.profile_url
                      )}
                      style={{ height: 40 }}
                      alt="Avatar"
                    />
                  </div>
                  <div className="note" style={{ height: "40px" }}>
                    <div className="note-time"></div>
                    <div className="note-content"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;

const DropDownItem = ({
  item,
  index,
  setSelectedVendor,
  setVendorValueArray,
  medicines,
  setMedicines,
  dispatch,
}) => {
  return (
    <Dropdown.Item
      key={index}
      onClick={() => {
        setSelectedVendor((prev) => {
          // Create a copy of the medicines array
          const updatedMedicines = [...medicines];

          // Find the product price entry that matches the selected vendor
          const selectedProductPrice = updatedMedicines[
            index
          ]?.product_price.find(
            (price) => price?.vendor?.vendor_name === item?.vendor_name
          );

          // If a matching product price is found, update the medicine
          if (selectedProductPrice) {
            updatedMedicines[index] = {
              ...updatedMedicines[index],
              vendor: selectedProductPrice?.vendor, // Update the vendor
              price: selectedProductPrice?.price, // Update the price
              mrp: selectedProductPrice?.mrp, // Update the MRP
              discount: updatedMedicines[index]?.discount || 15, // Optionally reset the discount
            };
          }

          // Update the medicines state
          setMedicines(updatedMedicines);
          dispatch(setManualOrderItems(updatedMedicines));

          return { ...prev, [index]: item }; // Update selected vendor
        });
      }}
    >
      {item.vendor_logo && (
        <img
          src={item?.vendor_logo}
          height="15px"
          width="15px"
          style={{ margin: "-2px 4px 0 3px", objectFit: "contain" }}
          alt={item?.vendor_name}
        />
      )}
      {item?.vendor_name}
    </Dropdown.Item>
  );
};
