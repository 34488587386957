import React, { useEffect, useState } from 'react';
import CoreDropDown from './CoreDropDown';
import { WeightNotUpdatedSinceList } from 'whealth-core-web/components/Helper';

function WeightNotUpdatedFilter(props) {
  const { filterData, setFilterData, clearData } = props;
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    let obj = { weight_not_updated: selectedId };
    setFilterData(obj);
  }, [selectedId]);

  useEffect(() => {
    setSelectedId('');
  }, []);

  useEffect(() => {
    setSelectedId('');
  }, [clearData]);

  useEffect(() => {
    const tempSelectedValue = filterData?.weight_not_updated;

    console.log('tempSelectedValue', tempSelectedValue);
    const selectedItem = WeightNotUpdatedSinceList.find((item) => item.id === tempSelectedValue);
    if (selectedItem) {
      setSelectedId(selectedItem.id);
    } else {
      setSelectedId('');
    }
  }, [filterData]);

  const renderHeading = () => {
    return <div className="filterHeadings">Weight Not Updated Since</div>;
  };

  const renderDropDown = () => {
    return (
      <CoreDropDown
        data={WeightNotUpdatedSinceList}
        labelStyle={{
          fontWeight: 'var(--lightWeight)',
        }}
        label=""
        placeholder="Weight Not Updated Since"
        value={selectedId || ''}
        setValue={(data) => {
          setSelectedId(data);
        }}
      />
    );
  };

  return (
    <div style={{ paddingLeft: 8 }}>
      {renderHeading()}
      {renderDropDown()}
    </div>
  );
}

export default WeightNotUpdatedFilter;
