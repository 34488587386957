import React, { useEffect, useRef, useState, useCallback } from "react";
import { AdminPanelHeader } from "components";
import useApiManager from "networking/ApiManager";
import {
  edit,
  filterFunnel,
  search,
  editWithBG,
  addWithBG,
  redRightTick,
  blueStaricon,
} from "res/images";
import CoreInputBox from "../CoreInputBox";
import Loader from "../Loader";
import Pagination from "../Pagination";
import CommonAlert from "../CommonAlert";
import RenderApolloTab from "../Apollo/RenderApolloTab";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover, Whisper, Dropdown, Button } from "rsuite";
import { useDispatch } from "react-redux";
import {
  setManualOrderId,
  setPatientAddress,
  setManualPatient,
  setManualOrderSummary,
} from "redux/Slices";
import { ToastContainer, toast } from "react-toastify";
import { useFilter } from "whealth-core-web/components/FilterContext";
import ManageInventoryActionModal from "../ManageInventoryActionModal";
import { useDebounce } from "whealth-core-web/hooks";
import { format } from "date-fns";
function ManageInventory({ index }) {
  const [orderLoadingState, setOrderLoadingState] = useState(undefined);
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalPages1, setTotalPages1] = useState();
  const [alert, setAlert] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const { filterData, setFilterData } = useFilter();
  const value = useRef("");
  const [isInventoryActionModalOpen, setIsInventoryActionModalOpen] =
    useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [isAddAction, setIsAddAction] = useState(false);
  const [inventoryMedicines, setInventoryMedicines] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isItPostReq, setIsItPostReq] = useState(false);
  const [medicineCount, setMedicineCount] = useState(0);
  const [medicineSearchCount, setMedicineSearchCount] = useState(0);
  const debouncedSearchStr = useDebounce(searchStr, 300);

  useEffect(() => {
    getInventoryMedicines();
    getMedicineCount();
  }, []);

  useEffect(() => {
    if (debouncedSearchStr.length > 0) {
      searchInventory();
      getMedicineSearchCount();
    } else {
      getInventoryMedicines();
      getMedicineCount();
    }
  }, [debouncedSearchStr, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchStr]);

  const refreshInventoryData = useCallback(() => {
    if (searchStr.length > 0) {
      searchInventory();
      getMedicineSearchCount();
    } else {
      getInventoryMedicines();
      getMedicineCount();
    }
  }, [currentPage, searchStr]);

  const resetScroll = () => {
    document.body.style.overflow = "";
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(toastMsg, {
        position: "top-right",
        autoClose: 1000,
        onClose: () => {
          refreshInventoryData();
          setIsSuccess(false);
          setIsError(false);
          setIsInventoryActionModalOpen(false);
          resetScroll();
        },
      });
    } else if (isError) {
      toast.error(toastMsg, {
        position: "top-right",
        autoClose: 1000,
        onClose: () => {
          refreshInventoryData();
          setIsSuccess(false);
          setIsError(false);
          setIsInventoryActionModalOpen(false);
          resetScroll();
        },
      });
    }
  }, [isSuccess, isError, refreshInventoryData]);

  const downloadOrder = (orderId) => {
    ApiClient.downloadOrder(orderId, { responseType: "arraybuffer" })
      .then((res) => {
        const imageUrl = res.data.image_url;
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = `order_${orderId}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log("downloadOrder error: ", err);
      });
  };

  const getInventoryMedicines = () => {
    let params = { page: currentPage, page_size: 10 };
    ApiClient.getInventoryMedicines(params)
      .then((res) => {
        setInventoryMedicines(res.data);
        setTotalPages1(res.headers["total-pages"]);
      })
      .catch((err) => {
        console.log("getInventoryMedicines error: ", err);
      });
  };

  const getMedicineCount = () => {
    ApiClient.getMedicineCount()
      .then((res) => {
        setMedicineCount(res?.data?.list_queryset_count);
      })
      .catch((err) => {
        console.log("getMedicineCount error: ", err);
      });
  };

  const getMedicineSearchCount = () => {
    setIsLoading(true);
    let params = { search_str: searchStr };
    ApiClient.getMedicineSearchCount(params)
      .then((res) => {
        setMedicineSearchCount(res?.data?.search_queryset_count);
        setMedicineCount(res?.data?.list_queryset_count);
      })
      .catch((err) => {
        console.log("getMedicineSearchCount error: ", err);
      });
  };

  const searchOrders = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr };
    ApiClient.searchOrders(params)
      .then((res) => {
        setIsLoading(false);
        if (value.current === params.search_str) {
          setTotalPages(res.headers["total-pages"]);
          setOrderList(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn("getOrders", err.response);
      });
  };

  const searchInventory = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr, page_size: 10 };
    ApiClient.searchInventory(params)
      .then((res) => {
        setIsLoading(false);
        setTotalPages1(res.headers["total-pages"]);
        setInventoryMedicines(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn("getOrders", err.response);
      });
  };

  const createFilterParams = (filterData, defaultObj) => {
    let params = { ...defaultObj };

    if (filterData.order_status) {
      if (filterData.order_status.length > 1) {
        params.status = filterData.order_status.join() || "";
      } else {
        params.status = filterData.order_status[0] || "";
      }
    }

    if (filterData.date) {
      if (Array.isArray(filterData.date)) {
        params.date_range = "";
      } else {
        params.date_range = filterData.date;
      }
    }

    if (filterData.orderTypes) {
      params.source = filterData.orderTypes;
    }

    if (filterData.priceRange) {
      params.min_price = filterData?.priceRange?.[0];
      params.max_price = filterData?.priceRange?.[1];
    }

    return params;
  };

  const getFilteredOrders = () => {
    setIsLoading(true);
    let defaultObj = {
      page: currentPage,
      status: "",
      date_range: "",
      source: "",
      min_price: "",
      max_price: "",
    };
    let params = createFilterParams(filterData, defaultObj);
    if (params.date_range) {
      params.date_range = String(params.date_range);
    }
    ApiClient.getFilteredOrders(params)
      .then((res) => {
        setOrderSummary(res.data);
        setTotalPages(res.headers["total-pages"]);
        setOrderList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getFilteredOrders err: ", err);
        setIsLoading(false);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className="w-100">
        <CoreInputBox
          placeholder="Search a Order by Patent name or Order ID"
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };
  const showTree = [
    { iconClass: "shopping_cart", title: "Orders", link: "/orders" },
    { iconClass: "", title: "Manage Inventory" },
  ];
  const renderHeader = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader
          hideSearch
          iconTitle="shopping_cart"
          showTree={showTree}
          title="Manage Inventory"
        />
      </div>
    );
  };

  const handleViewOrder = (id, address, user) => {
    dispatch(setManualOrderId(id));
    dispatch(setPatientAddress(address));
    dispatch(setManualPatient(user));
  };

  const handleDetail = (
    index,
    orderDetail,
    orderStatus,
    patientName,
    patientId
  ) => {
    const idStr = orderDetail?.order_id;
    const orderId = Number(idStr.substring(6));
    const userAddress = orderDetail?.user?.address;
    const user = orderDetail.user;
    handleViewOrder(orderId, userAddress, user);
    if (orderStatus === "draft") {
      navigate(`/orders/${index}/${orderId}/${patientId}/${orderStatus}`, {
        state: { orderStatus, patientName, orderId },
      });
    } else {
      navigate(`/orders/${index}/${orderId}/${patientId}`);
    }
  };

  const medicineStatus = [
    {
      title: "Published",
      id: "published",
    },
    {
      title: "Unpublished",
      id: "unpublished",
    },
    {
      title: "Listed",
      id: "listed",
    },
  ];

  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    return (
      <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
          <Dropdown.Item eventKey="1">View Details</Dropdown.Item>
          <Dropdown.Item eventKey="2">Download</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  });

  const resetFormData = () => {
    setSelectedRowDetails(null);
    setIsAddAction(false);
    setIsItPostReq(false);
  };

  const showInventoryActionModal = useCallback(() => {
    if (!isInventoryActionModalOpen && (isSuccess || isError)) return null;

    return (
      <div
        className="offcanvas1 offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight1"
        aria-labelledby="offcanvasRightLabel1"
      >
        <ManageInventoryActionModal
          selectedRowDetails={selectedRowDetails}
          isAddAction={isAddAction}
          setIsSuccess={setIsSuccess}
          setToastMsg={setToastMsg}
          isItPostReq={isItPostReq}
          setIsInventoryActionModalOpen={setIsInventoryActionModalOpen}
          setIsError={setIsError}
          onClose={resetFormData}
        />
      </div>
    );
  }, [
    isInventoryActionModalOpen,
    selectedRowDetails,
    isAddAction,
    isSuccess,
    isError,
  ]);
  const showOrders = () => (
    <div className="mb-4">
      {imageUrl && (
        <>
          <img src={imageUrl} alt="Downloaded Order" />
          <a href={imageUrl} download={`order_${orderId}.png`}>
            Download Image
          </a>
        </>
      )}
      <div className="articleHeader stickyHeader">
        {renderHeader()}
        <div className="hrLine mb-3" />
      </div>
      <div className="px-4 wrapperContent">
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex justify-content-between gap-2"></div>
          <div className="w-100">
            <CoreInputBox
              style={{ paddingLeft: "40px" }}
              placeholder="Search Medicine"
              value={searchStr}
              setValue={(text) => {
                setSearchStr(text);
              }}
              leftIcon={search}
            />
          </div>
          <div className="d-flex flex-row align-items-center w-25 ">
            <div className="  ms-4 w-100">
              {/* <button
                className="reusableBtnActive  reusableBtn  w-100 "
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                onClick={() => {
                  setIsInventoryActionModalOpen(true);
                  setSelectedRowDetails(null);
                  setIsAddAction(true);
                  setIsItPostReq(true);
                }}
              >
                + Add New Medicine
              </button> */}
              {showInventoryActionModal()}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row w-30 mb-2 gap-2">
          <div>
            <div
              className="searchInfoTitle"
              style={{
                color:
                  medicineSearchCount > 0 || searchStr.length > 0
                    ? "#757575"
                    : "#243B86",
              }}
            >
              {`New Listed Medicine (${medicineCount})`}
            </div>
            <div className="searchInfoSubtext">
              {medicineSearchCount === 0 || searchStr.length === 0 ? (
                "Add details of medicine to publish"
              ) : (
                <>
                  Showing search results for "<b>{searchStr}</b>"
                </>
              )}
            </div>
          </div>
          {medicineSearchCount > 0 && searchStr.length > 0 && (
            <div
              className="searchInfoTitle"
              style={{ color: "#243B86" }}
            >{`Search Results (${medicineSearchCount})`}</div>
          )}
        </div>
        {alert.type == "alert-success" && (
          <CommonAlert
            className="mt-3"
            setIsShowAlert={setAlert}
            isShowAlert={alert.message}
            alertType={alert.type}
          />
        )}
        <div className="table-responsive order-table">
          <table class="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <strong>SKU</strong>
                </th>
                <th scope="col">
                  <strong>Name</strong>
                </th>
                <th scope="col">
                  <strong>Formulation</strong>
                </th>
                {/* <th scope="col">
                  <strong>Manufacturer</strong>
                </th> */}
                <th scope="col">
                  <strong>Created At</strong>
                </th>
                <th scope="col">
                  <strong>Created By</strong>
                </th>
                <th scope="col">
                  <strong>Variation</strong>
                </th>
                <th scope="col">
                  <strong>MRP</strong>
                </th>
                <th scope="col">
                  <strong>Out of Stock</strong>
                </th>
                <th scope="col">
                  <strong>Status</strong>
                </th>
                <th scope="col" style={{ width: "100px" }}></th>
                <th scope="col" style={{ width: "100px" }}></th>
              </tr>
            </thead>

            <tbody>
              {inventoryMedicines?.length || isLoading ? (
                inventoryMedicines.flatMap((item) => {
                  const lineItems = item?.product?.product_line_items || [];
                  if (lineItems.length === 0) {
                    return (
                      <tr key={item?.id}>
                        <td>
                          <div>
                            <div>{item?.product?.line_items?.[0]?.sku}</div>
                            {item?.product?.line_items?.[0]?.active && (
                              <div className="d-flex align-items-center justify-content-center mt-1">
                                <img
                                  src={blueStaricon}
                                  width={15}
                                  height={15}
                                  alt="Primary"
                                />
                                <span
                                  className="ms-1"
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#243B86",
                                  }}
                                >
                                  Primary
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td width={120}>{item?.name}</td>
                        <td
                          className="capitalize"
                          style={{
                            color: `${
                              item?.status === "listed" ? "#757575" : "#333333"
                            }`,
                          }}
                        >
                          {item?.formulation}
                        </td>
                        {/* <td width={300}>
                          {item?.product?.line_items?.[0]?.manufacturer?.name}
                        </td> */}
                        <td width={150}>
                          <div>
                            {format(new Date(item?.created_at), "dd-MM-yyyy")}
                          </div>
                          <div>
                            {format(new Date(item?.created_at), "hh:mm a")}
                          </div>
                        </td>
                        <td width={150}>{item?.admin?.full_name}</td>
                        <td>
                          {item?.product?.line_items?.[0]?.variation?.type &&
                          item?.product?.line_items?.[0]?.variation
                            ?.variation ? (
                            <>
                              {item.product.line_items[0].variation.type} of{" "}
                              {item.product.line_items[0].variation.variation}
                            </>
                          ) : null}
                        </td>
                        <td width={150}>
                          {item?.product?.line_items?.[0]?.mrp
                            ? `₹ ${item.product.line_items[0].mrp}`
                            : ""}
                        </td>
                        <td width={150} className="text-capitalize">
                          {item?.product?.line_items?.[0]?.oos && (
                            <img
                              src={redRightTick}
                              alt="unavailable"
                              width={20}
                              height={20}
                            />
                          )}
                        </td>
                        <td>
                          <span
                            className={`align-items-center badge capitalize active ${item.status}`}
                          >
                            {item?.status}
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => {
                              setIsInventoryActionModalOpen(true);
                              setSelectedRowDetails(item);
                              setIsAddAction(item?.status === "listed");
                              setIsItPostReq(false);
                            }}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            height={20}
                            width={20}
                            src={
                              item?.status === "listed" ? addWithBG : editWithBG
                            }
                            className="ml-10 me-1 pointer"
                            alt="More options"
                          />
                        </td>
                      </tr>
                    );
                  } else {
                    return lineItems.map((lineItem, index) => (
                      <tr key={`${item?.id}-${index}`}>
                        <td>
                          <div>
                            <div>{lineItem?.sku}</div>
                            {lineItem?.active && (
                              <div className="d-flex align-items-center justify-content-center mt-1">
                                <img
                                  src={blueStaricon}
                                  width={15}
                                  height={15}
                                  alt="Primary"
                                />
                                <span
                                  className="ms-1"
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#243B86",
                                  }}
                                >
                                  Primary
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td width={120}>{item?.name}</td>
                        <td
                          className="capitalize"
                          style={{
                            color: `${
                              item?.status === "listed" ? "#757575" : "#333333"
                            }`,
                          }}
                        >
                          {item?.formulation}
                        </td>
                        {/* <td width={300}>{lineItem?.manufacturer?.name}</td> */}
                        <td width={150}>
                          <div>
                            {format(new Date(item?.created_at), "dd-MM-yyyy")}
                          </div>
                          <div>
                            {format(new Date(item?.created_at), "hh:mm a")}
                          </div>
                        </td>
                        <td width={150}>{item?.admin?.full_name}</td>
                        <td>
                          {/* {lineItem?.variation?.pack_form &&
                          lineItem?.variation?.variation ? ( */}
                          <>
                            {lineItem.variation.pack_form} of{" "}
                            {lineItem.variation.type}
                          </>
                          {/* ) : null} */}
                        </td>
                        <td width={150}>{"₹" + lineItem?.prices?.[0]?.mrp}</td>
                        <td width={150} className="text-capitalize">
                          {lineItem?.oos ? (
                            <img
                              src={redRightTick}
                              alt="unavailable"
                              width={20}
                              height={20}
                            />
                          ) : (
                            <>Out of stock</>
                          )}
                        </td>
                        <td>
                          <span
                            className={`align-items-center badge capitalize active ${item.status}`}
                          >
                            {item?.status}
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => {
                              setIsInventoryActionModalOpen(true);
                              setSelectedRowDetails({
                                ...item,
                                selectedLineItem: lineItem,
                                editAddFromList: true,
                                isActiveVariant: lineItem?.active || false,
                              });
                              setIsAddAction(item?.status === "listed");
                              setIsItPostReq(false);
                            }}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            height={20}
                            width={20}
                            src={
                              item?.status === "listed" ? addWithBG : editWithBG
                            }
                            className="ml-10 me-1 pointer"
                            alt="More options"
                          />
                        </td>

                        <td>
                          {lineItem?.is_variation_available &&
                            lineItem?.variation?.variation !== "None" && (
                              <img
                                onClick={() => {
                                  setIsInventoryActionModalOpen(true);
                                  setSelectedRowDetails({
                                    ...item,
                                    editAddFromList: true,
                                    isActiveVariant: lineItem?.active || false,
                                  });
                                  setIsAddAction(true);
                                  setIsItPostReq(false);
                                }}
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                height={20}
                                width={20}
                                src={addWithBG}
                                className="ml-10 me-1 pointer"
                                alt="Add Variation"
                              />
                            )}
                          {item?.status === "published" && (
                            <img
                              onClick={() => {
                                setIsInventoryActionModalOpen(true);
                                setSelectedRowDetails(item);
                                setIsAddAction(true);
                                setIsItPostReq(false);
                              }}
                              type="button"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              height={20}
                              width={20}
                              src={addWithBG}
                              className="ml-10 me-1 pointer"
                              alt="More options"
                            />
                          )}
                        </td>
                      </tr>
                    ));
                  }
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    {" "}
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-4">
        {(inventoryMedicines?.length > 0 ||
          currentPage > 1 ||
          totalPages1 > 1) && (
          <Pagination
            data={inventoryMedicines}
            length={inventoryMedicines?.count}
            totalPages={totalPages1}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="wrapper">
        <Loader show={isLoading} />
        {!index ? (
          showOrders()
        ) : (
          <RenderApolloTab
            order={orderList}
            setOrderList={setOrderList}
            searchOrder={searchOrders}
            screenLoading={isLoading}
            orderLoadingState={orderLoadingState}
            setOrderLoadingState={setOrderLoadingState}
          />
        )}

        <ToastContainer toastStyle={{ backgroundColor: "#EBF8E7" }} />
      </div>
    </>
  );
}

export default ManageInventory;
