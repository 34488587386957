import { useState, useEffect } from "react";
import useApiManager from "networking/ApiManager";

const useFormulation = (formulation, selectedRowDetails) => {
  const [formulationData, setFormulationData] = useState([]);
  const ApiClient = useApiManager();

  useEffect(() => {
    const getFormulation = (formulationType) => {
      if (!formulationType) return;

      ApiClient.getFormulation(formulationType)
        .then((res) => {
          const modifiedRes = res.data.map((item) => ({
            ...item,
            name: `${item.name}, ${item.variation}, ${item.type}`,
          }));
          setFormulationData(modifiedRes);
        })
        .catch((err) => {
          console.log("getFormulation error: ", err);
        });
    };

    if (selectedRowDetails?.formulation) {
      getFormulation(formulation || selectedRowDetails?.formulation);
    } else if (formulation) {
      getFormulation(formulation);
    }
  }, [formulation, selectedRowDetails?.formulation]);

  return formulationData;
};

export default useFormulation;
